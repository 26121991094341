import { IconProps } from "@tabler/icons-react";
import React, { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";

interface DropdownItem {
  to: string;
  icon: React.ComponentType<IconProps>;
  label?: string;
}

interface DropdownProps {
  isOpen: boolean;
  toggle: MouseEventHandler<HTMLButtonElement>;
  items: DropdownItem[];
  mainItem?: string;
  mainIcon?: React.ComponentType<IconProps>;
  isSidebarOpen?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  toggle,
  items,
  mainItem,
  mainIcon: MainIcon,
  isSidebarOpen,
}) => {
  return (
    <li className="relative m-0 p-0 bg-transparent border-0 font-sans !hover:no-underline">
      <button
        className="w-full text-left text-white text-xl hover:text-yellow-200"
        onClick={toggle}
      >
        {MainIcon && <MainIcon stroke={2} className="inline-block mr-2" />}
        {isSidebarOpen ?? mainItem}
      </button>
      {isOpen && (
        <ul className={`${isSidebarOpen ?? "ml-4"} space-y-2 mt-2`}>
          {items.map(({ to, icon: Icon, label }, index) => (
            <li
              key={index}
              className="m-0 p-0 bg-transparent border-0 font-sans !hover:no-underline"
            >
              <NavLink
                to={to}
                className={({ isActive }) =>
                  isActive
                    ? "text-yellow-300 text-xl flex items-center"
                    : "text-white text-xl hover:text-yellow-200 flex items-center"
                }
              >
                <Icon stroke={2} className="inline-block mr-2" />
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default Dropdown;
