import Modal from "../../components/Modal";

const OtherProduct = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title=" " size="w-4/12">
      <div className="p-8 text-center">
        <div className="font-semibold text-2xl text-gray-800 mb-4">
          <span>Coming Soon!</span>
        </div>
        <p className="text-gray-600 text-lg">
          We're working hard to bring this product to you. Stay tuned!
        </p>
      </div>
    </Modal>
  );
};

export default OtherProduct;
