/* Login Details */
export const USER_LOGIN = "/user_login";
export const RESET_LINK = "/reset_link";
export const RESET_PASSWORD = "/reset_password";

/* JSON */
export const INVOICE_TO_JSON = "/invoice_to_json";
export const VIEW_JSON = "/view_json";
export const EDIT_JSON = "/edit_json";
export const JSON_TO_HTML = "/json_to_html";
export const HTML_TO_PDF = "/html_to_pdf";
export const DOWNLOAD_JSON = "/download_json";
export const DOWNLOAD_PDF = "/download_pdf";

/* CSV */
export const INVOICE_TO_CSV = "/invoice_to_csv";
export const VIEW_CSV = "/view_csv";
export const EDIT_CSV = "/edit_csv";
export const DOWNLOAD_CSV = "/download_csv";

/* XML */
export const INVOICE_TO_XML = "/invoice_to_xml";
export const VIEW_XML = "/view_xml";
export const EDIT_XML = "/edit_xml";
export const DOWNLOAD_XML = "/download_xml";

/* Create and update Format */
export const UPLOAD_INVOICE = "/upload_invoice";
export const ADD_BUYER_SUPPLIER = "/add_buyer_supplier";
export const VALIDATE_FORMAT_NAME = "/validate_format_name";
export const ADD_DICTIONARY = "/add_dict";
export const ADD_FORMAT_HEADERS = "/format_headers";
export const ADD_FIELDS = "/add_fields";
export const MAPPING_FIELDS = "/mapping_fields";
export const MAPPING_FILE_STORED_PROC = "/mapping_file_stored_proc";
export const UPDATE_MAPPING_FIELDS = "/update_mapping_fields";
export const VIEW_FIELDS = "/view_fields";
export const VIEW_MAPPING_FIELDS = "/view_mapping_fields";
export const VIEW_FORMAT_HEADERS = "/view_format_headers";
export const GET_OUTPUT_TYPES = "/get_output_types";
export const RESOLVE_NAME_DICTIONARY = "/resolve-name";
export const SETUP_LOOKUP_DETAILS = "/setup_file";
export const UPDATE_LOOKUP_DETAILS = "/update_file";
export const VIEW_LOOKUP_DICTIONARY = "/view_lookup_dict";
export const VIEW_SETUP_FILE = "/view_setup_file";
export const UPDATE_LOOKUP_DICT = "/update_lookup_dict";

/* Stats */
export const GET_INVOICE_AMOUNT_BY_VENDOR_NAME =
  "/get_invoice_amount_by_vendor_name";
export const GET_INVOICE_AMOUNT_BY_ORGID = "/get_invoice_amount_by_orgid";
export const GET_INVOICE_AMOUNT_BY_FORMAT_NAME =
  "/get_invoice_amount_by_format_name";

/* Monitoring */
export const VIEW_MONITORING_FILE = "/view_file";

/* Active/Inactive Format */
export const PUT_FORMAT_STATUS = "/put_format_status";

export const INVOICE_VERSIONS = "/invoice_versions";
export const GET_FORMAT_NAME = "/get_format_name";
export const INVOICE_VERSION_BY_INVOICE_NAME =
  "/invoice_version_by_invoice_name";
export const VISIT_COUNT = "/visit_count";
export const GET_INVOICE_COUNT = "/get_invoice_count";
export const GET_INVOICE_COUNT_BY_ORGID = "/get_invoice_count_by_orgid";

export const ADD_CONFIG_API = "/add_api_config";
export const GET_API_CONFIG_LIST = "/get-api_config";
export const EDIT_CONFIG_API = "/update_api_config";
export const BATCH_PROCESS = "/batch_process";
export const BATCH_CONVERSION = "/batch_conversion";
export const CHARTS = "/home";
export const GetInvoiceAndFormatCounts = "/get_invoice_and_format_counts";