import { IconCloudUpload, IconX } from "@tabler/icons-react";
import React, { useState, ChangeEvent, DragEvent, useEffect } from "react";
import axios from "axios";
import WarningPopup from "../../components/WarningPopup";
import "react-dropdown/style.css";
import { useToast } from "../../components/Toast";
import {
  BATCH_PROCESS,
  GET_FORMAT_NAME,
  UPLOAD_INVOICE,
} from "../../utils/api-details/ApiList";
import Select from "react-select";
import { SingleValue } from "react-select";
import { useNavigate } from "react-router-dom";

interface FormatItem {
  INVOICE_FORMAT_ID: number;
  FORMAT_NAME: string;
  status: string;
}

interface errorFileds {
  error: string;
  filename: string;
}
const FileUpload = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlBulkUpload = process.env.REACT_APP_API_URL_Bulk;
  const [isDragOver, setIsDragOver] = useState(false);
  // const [uploading, setUploading] = useState(false);
  // const [fileName, setFileName] = useState<string[]>([]);
  // const [fileFormatErrorMessage, setFileFormatErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const userType = sessionStorage.getItem("user_type");
  const [formatList, setFormatList] = useState<FormatItem[]>([]);
  // const [jsonFile, setJsonFile] = useState<string[]>([]);
  const { showToast } = useToast();
  const upload_invoice = UPLOAD_INVOICE;
  const get_format_name = GET_FORMAT_NAME;
  const [uploadInvoiceFiles, setUploadInvoiceFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const batch_process = BATCH_PROCESS;
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        if (results) {
          // Sort results in descending order based on INVOICE_FORMAT_ID
          const sortedResults = (results as FormatItem[]).sort(
            (a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID
          );
          // Extract unique FORMAT_NAME values
          const uniqueFormatNames: string[] = [];
          sortedResults.forEach((item: { FORMAT_NAME: string }) => {
            if (!uniqueFormatNames.includes(item.FORMAT_NAME)) {
              uniqueFormatNames.push(item.FORMAT_NAME);
            }
          });
          // Set the format list with unique format names
          // setFormatList(uniqueFormatNames);
          const formattedResults = sortedResults.map((item: FormatItem) => ({
            INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
            FORMAT_NAME: item.FORMAT_NAME,
            status: item.status,
          }));

          setFormatList(formattedResults);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, [apiUrl, orgID, userID]);

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (): void => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const acceptedFiles = Array.from(event.dataTransfer.files).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    // Check if the number of files exceeds the limit (10 files)
    if (uploadInvoiceFiles.length + acceptedFiles.length > 10) {
      showToast("You can only upload a maximum of 10 files", "error");
      return;
    }
    setUploadInvoiceFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles = Array.from(event.target.files || []).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    // Check if the number of files exceeds the limit (10 files)
    if (uploadInvoiceFiles.length + acceptedFiles.length > 10) {
      showToast("You can only upload a maximum of 10 files", "error");
      return;
    }
    setUploadInvoiceFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleRemoveFile = (fileToRemove: File) => {
    setUploadInvoiceFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const handleFileUpload = (files: File[]): void => {
    setIsLoading(true)
    // Check if all files are PDFs
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== "application/pdf") {
        showToast("Invalid file type. Please upload only PDF files", "error");
        return;
      }
    }
    // Replace spaces in filenames with underscores and notify the user
    const updatedFileNames = files.map((file) => {
      if (file.name.includes(" ")) {
        showToast("File name has space, replacing with _", "success");
      }
      return file.name.replace(/ /g, "_");
    });
    const api = files.length === 1 ? upload_invoice : batch_process;
    const url = files.length === 1 ? apiUrl : apiUrlBulkUpload;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(files.length === 1 ? "file" : "files", file);
    });
    axios
      .post(`${url + api}`, formData, {
        params: {
          user_type: userType === "A" ? "admin" : "user",
          org_id: orgID,
          format_name: selectedOption,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
          setIsLoading(false)
        if (files.length > 1) {
          // Multiple files upload
          const errorMessages = response?.data?.message.filter(
            (item: any) => item.error
          );
          // console.log("errorMessages", errorMessages);
          // setFailedFile(errorMessages);
          // if (errorMessages.length !== 0) {
          //   setHasError(true);
          // }
          // setErrorFor("upload");
          const filenames = response?.data?.message
            ?.filter((item: any) => !item.error)
            ?.map((item: any) => item.filename);
          // console.log("filenames without errors", filenames);
          // Directly set filenames in the state and navigate
          // setJsonFile(filenames);
          // setFileName(filenames);

          // Data to pass
          const fileDetailsData = {
            isFileDetailsModalOpen: true,
            fileName: filenames,
            selectedOption: selectedOption,
            errorMessages: errorMessages,
            isError: errorMessages.length !== 0 ? true : false,
            uploadErrorFor: "upload",
          };
          navigate("/upload_file/File_details", { state: fileDetailsData });
        } else {
          // Single file upload
          // setJsonFile([response?.data?.filename]);
          const fileDetailsData = {
            isFileDetailsModalOpen: true,
            fileName: [response?.data?.filename], // Use the single filename
            selectedOption: selectedOption,
            uploadErrorFor: "upload",
          };
          navigate("/upload_file/File_details", { state: fileDetailsData });
        }
        // setIsFileUploaded(true);
      })
      .catch((error) => {
        setIsLoading(false)
        // setUploading(false);
        // setFileName([]);
        if (axios.isCancel(error)) {
          console.log("File upload canceled");
          showToast("File upload canceled", "error");
        } else {
          const errorMsg =
            error?.response?.data?.detail ||
            "An error occurred during the file upload";
          console.error("Error uploading files:", error);
          showToast(errorMsg, "error");
        }
      })
      .finally(() => {
        console.log("In finally block");
        setIsLoading(false)
        // setUploading(false);
        // setProgress(100);
      });
  };

  const handleExtractClick = () => {
    handleFileUpload(uploadInvoiceFiles);
  };

  const handleButtonClick = () => {
    const fileInput = document.querySelector(
      "input[type='file']"
    ) as HTMLInputElement;
    fileInput.click();
  };

  const handleClearAll = () => {
    setUploadInvoiceFiles([]);
  };

  const sortedOptions = formatList
    ?.filter((option) => option.status !== "IN ACTIVE")
    .sort((a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID)
    .map((option) => ({
      label: option.FORMAT_NAME,
      value: option.FORMAT_NAME,
    }));

  const handleSelectChange = (
    selected: SingleValue<{ label: string; value: string }>
  ) => {
    if (selected) {
      setSelectedOption(selected.value);
    } else {
      setSelectedOption("");
    }
  };

  return (
    <div>
      <div className="flex  items-center justify-center h-full w-full">
        <UploadContent />
      </div>
      {/* 
      {fileFormatErrorMessage && (
        <WarningPopup
          isOpen={isFileFormatError}
          onClose={closeFileDetailsModal}
          title=" "
          showCancelButton={true}
          size="w-2/5"
        >
          <div className="p-3 text-center">
            <span className=" text-lg">{fileFormatErrorMessage}</span>
          </div>
        </WarningPopup>
      )} */}
    </div>
  );

  function UploadContent() {
    return (
      <div className="w-full p-6">
        <div className="flex flex-col w-52 max-w-xs">
          <label className="font-bold mb-2 text-gray-700" htmlFor="dropdown">
            Payable To:
          </label>
          {/* Select Format */}
          <div>
            <Select
              isMulti={false}
              options={sortedOptions}
              value={
                selectedOption
                  ? { label: selectedOption, value: selectedOption }
                  : null
              }
              onChange={handleSelectChange}
              placeholder="Select format"
            />
          </div>
        </div>
        <h2 className="font-semibold text-xl text-blue-700 mt-4">
          UPLOAD FILE
        </h2>
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`border-2 p-4 h-72  rounded-lg text-center ${
            isDragOver
              ? "bg-blue-50 border-blue-500"
              : "bg-blue-50 border-gray-300"
          }`}
        >
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="hidden"
            multiple
          />
          <div className="flex flex-col justify-center items-center">
            <IconCloudUpload stroke={2} color="#3b82f6" className="h-14 w-14" />
            <p className="mt-2 text-gray-700">
              Choose a file or Drag & drop it here
            </p>
            <button
              onClick={handleButtonClick}
              className="mt-4 w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Browse Files
            </button>
          </div>
        </div>

        {/* File List */}
        <div
          className="mt-6 max-h-96"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
            overflowX: "auto",
            WebkitOverflowScrolling: "touch",
            whiteSpace: "nowrap",
          }}
        >
          <div
            className={`p-2 grid gap-2 ${
              uploadInvoiceFiles?.length > 4 ? "grid-cols-2" : "grid-cols-1"
            }`}
          >
            {uploadInvoiceFiles?.map((file, index) => {
              const truncatedName =
                file?.name?.length > 30
                  ? `${file?.name?.slice(0, 30)}...`
                  : file?.name;
              return (
                <div
                  key={index}
                  className="relative flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group"
                >
                  <div className="flex items-center">
                    <p className="text-gray-700 truncate">{truncatedName}</p>
                  </div>

                  {/* Custom Tooltip */}
                  {file?.name?.length > 30 && (
                    <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded-md shadow-md">
                      {file.name}
                    </div>
                  )}
                  <IconX
                    onClick={() => handleRemoveFile(file)}
                    stroke={1}
                    className="cursor-pointer text-gray-700 hover:text-red-500 transition-colors"
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* Extract Button */}
        {uploadInvoiceFiles.length >= 1 && (
          <div className="mt-4 flex justify-center space-x-4">
            <button
              onClick={handleExtractClick}
              className="w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              disabled={isLoading || uploadInvoiceFiles.length === 0}
            >
              {isLoading ? "Uploading..." : "Upload"}
            </button>

            <button
              onClick={handleClearAll}
              className="w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              disabled={isLoading || uploadInvoiceFiles.length === 0}
            >
              Clear
            </button>
          </div>
        )}
      </div>
    );
  }
};

export default FileUpload;
