import React from 'react';

const Spinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div
        className="border-4 border-t-4 border-blue-500 border-solid rounded-full w-16 h-16 animate-spin"
        style={{ borderTopColor: '#3498db' }}
      ></div>
    </div>
  );
};


export default Spinner;
