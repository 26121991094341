import React from "react";
import NavLinkItem from "./NavLinkItem";
import Dropdown from "./SubNavLinkItem";
import {
  IconLayoutDashboard,
  IconFilePlus,
  IconFilePencil,
  IconAdjustmentsAlt,
  IconHeartRateMonitor,
  IconReportAnalytics,
  IconSettings,
  IconChartPie,
  IconUserPlus,
  IconUserCancel,
  IconUpload,
  IconHistory,
  IconFileText,
  IconLogout,
} from "@tabler/icons-react";
import { CHARTS } from "../../utils/api-details/ApiList";

interface SidebarProps {
  isSidebarOpen?: boolean;
  isHovered?: boolean;
  isAdmin?: boolean;
  isFormatOpen?: boolean;
  toggleFormatDropdown: React.MouseEventHandler<HTMLButtonElement>;
  handleMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  handleMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  handleLogout: () => void;
}

// Sidebar component
const Sidebar: React.FC<SidebarProps> = ({
  isSidebarOpen = false,
  isHovered = false,
  isAdmin = false,
  isFormatOpen = false,
  toggleFormatDropdown,
  handleMouseEnter,
  handleMouseLeave,
  handleLogout,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const charts = CHARTS;

  const handleChartClick = () => {
    window.open(apiUrl + charts, "_blank");
  };

  return (
    <div className="flex">
      {isSidebarOpen && (
        <div
          className={`bg-blue-500 text-white mt-20 w-64 p-6 fixed inset-0 transition-transform transform ${
            isSidebarOpen || isHovered ? "translate-x-0" : "-translate-x-full"
          } ease-in-out duration-300 rounded-lg overflow-auto`}
          style={{
            scrollbarColor: "#3b82f6 #f1f1f1",
            overflowX: "auto",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <ul>
            <div className="!space-y-6">
              <NavLinkItem
                to="/dashboard"
                icon={IconLayoutDashboard}
                label="Dashboard"
              />
              {/* Admin-Specific Links */}
              {isAdmin && (
                <>
                  <Dropdown
                    isOpen={isFormatOpen}
                    toggle={toggleFormatDropdown}
                    items={[
                      {
                        to: "/create-format",
                        icon: IconFilePlus,
                        label: "Create Format",
                      },
                      {
                        to: "/update-format",
                        icon: IconFilePencil,
                        label: "Update Format",
                      },
                      {
                        to: "/FormatDetails",
                        icon: IconAdjustmentsAlt,
                        label: "Enable/Disable Format",
                      },
                    ]}
                    mainItem={"Format"}
                    mainIcon={IconFileText}
                  />
                  <NavLinkItem
                    to="/monitoring"
                    icon={IconHeartRateMonitor}
                    label="Monitoring"
                  />
                  <NavLinkItem
                    to="/stats"
                    icon={IconReportAnalytics}
                    label="Stats"
                  />
                  <NavLinkItem
                    to="/ConfigApi"
                    icon={IconSettings}
                    label="Config API"
                  />
                  <NavLinkItem
                    to="/dashboard"
                    icon={IconChartPie}
                    label="Charts"
                    onClick={handleChartClick}
                  />
                  {/* Disabled Links */}
                  <NavLinkItem
                    to="#"
                    icon={IconUserPlus}
                    label="Request New User"
                    isDisabled
                  />
                  <NavLinkItem
                    to="#"
                    icon={IconUserCancel}
                    label="Block User"
                    isDisabled
                  />
                </>
              )}

              {/* Non-Admin Links */}
              {!isAdmin && (
                <>
                  <NavLinkItem
                    to="/upload_file"
                    icon={IconUpload}
                    label="Upload File"
                  />
                  <NavLinkItem
                    to="/history"
                    icon={IconHistory}
                    label="History"
                  />
                </>
              )}
            </div>
            <div className={isAdmin ? "mt-32" : "mt-96"}>
              <NavLinkItem
                to="/"
                icon={IconLogout}
                label="Logout"
                onClick={handleLogout}
              />
            </div>
          </ul>
        </div>
      )}

      {!isSidebarOpen && (
        <div
          className={`bg-blue-500 text-white mt-20 w-20 p-6 fixed inset-0 transition-transform transform ${
            isHovered ? "translate-x-0" : "-translate-x-full"
          }ease-in-out duration-300 rounded-lg overflow-auto`}
          style={{
            scrollbarColor: "#3b82f6 #f1f1f1",
            overflowX: "auto",
            WebkitOverflowScrolling: "touch",
          }}
          onMouseEnter={handleMouseEnter ? handleMouseEnter : undefined}
          onMouseLeave={handleMouseLeave ? handleMouseLeave : undefined}
        >
          <ul>
            <div className="space-y-6">
              <NavLinkItem to="/dashboard" icon={IconLayoutDashboard} />
              {/* Admin-Specific Links */}
              {isAdmin && (
                <>
                  <Dropdown
                    isOpen={isFormatOpen}
                    toggle={toggleFormatDropdown}
                    items={[
                      { to: "/create-format", icon: IconFilePlus },
                      { to: "/update-format", icon: IconFilePencil },
                      { to: "/FormatDetails", icon: IconAdjustmentsAlt },
                    ]}
                    mainItem={"Format"}
                    mainIcon={IconFileText}
                    isSidebarOpen={isSidebarOpen}
                  />
                  <NavLinkItem to="/monitoring" icon={IconHeartRateMonitor} />
                  <NavLinkItem to="/stats" icon={IconReportAnalytics} />
                  <NavLinkItem to="/ConfigApi" icon={IconSettings} />
                  <NavLinkItem
                    to="/dashboard"
                    icon={IconChartPie}
                    onClick={handleChartClick}
                  />
                  {/* Disabled Links */}
                  <NavLinkItem to="/addUser" icon={IconUserPlus} isDisabled />
                  <NavLinkItem
                    to="/blockUser"
                    icon={IconUserCancel}
                    isDisabled
                  />
                </>
              )}

              {/* Non-Admin Links */}
              {!isAdmin && (
                <>
                  <NavLinkItem to="/upload_file" icon={IconUpload} />
                  <NavLinkItem to="/history" icon={IconHistory} />
                </>
              )}
            </div>
            <div className={isAdmin ? "mt-32" : "mt-96"}>
              <NavLinkItem to="/" icon={IconLogout} onClick={handleLogout} />
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
