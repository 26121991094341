import { useEffect, useState } from "react";
import RadioGroupComponent from "../../../../components/RadioGroupComponent";
import BuyerLookupDeatils from "./BuyerLookupDetails";
import SupplierLookupDeatils from "./SupplierLookupDetails";
import axios from "axios";
import {
  VIEW_LOOKUP_DICTIONARY,
  VIEW_SETUP_FILE,
} from "../../../../utils/api-details/ApiList";

interface LookupDetails {
  url?: string;
  method?: string;
  username?: string;
  password?: string;
  requestBody?: {};
  pathParam?: {};
}

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}

const ConfigureLookup = ({
  setActiveTab,
  activeTab,
  setDictionaryObject,
  dictionaryObject,
  formatName,
  supportedOutput,
  buyerSupplier,
  isEdit,
  buyerDictionaryObject,
  setBuyerDictionaryObject,
  buyerLookupDetails,
  setBuyerLookupDetails,
  setSupplierDictionaryObject,
  supplierDictionaryObject,
  supplierLookupDetails,
  setSupplierLookupDetails,
}: {
  activeTab: string;
  setActiveTab: (value: string) => void;
  setDictionaryObject: (val: string) => void;
  dictionaryObject?: string;
  formatName?: string;
  supportedOutput: string[];
  buyerSupplier?: BuyerSupplierData[];
  // buyerSupplier?: BuyerSupplierData;
  isEdit?: boolean;
  buyerDictionaryObject?: string;
  setBuyerDictionaryObject: (val: string) => void;
  buyerLookupDetails: LookupDetails;
  setBuyerLookupDetails: (details: LookupDetails) => void;
  setSupplierDictionaryObject: (val: string) => void;
  supplierDictionaryObject?: string;
  supplierLookupDetails: LookupDetails;
  setSupplierLookupDetails: (details: LookupDetails) => void;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const view_lookup_dict = VIEW_LOOKUP_DICTIONARY;
  const view_setup_file = VIEW_SETUP_FILE;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedLevel, setSelectedLevel] = useState("format_name");
  const [selectedSetup, setSelectedSetup] = useState("dictionary");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState();
  const [buyerLookupType, setBuyerLookupType] = useState();
  const [supplierLookupType, setSupplierLookupType] = useState();

  const levels = [
    { Label: "Invoice Format Level", value: "format_name" },
    { Label: "Organization Level", value: "org" },
  ];

  const setUps = [
    { Label: "Dictionary Setup", value: "dictionary" },
    { Label: "API Setup Required", value: "Api" },
  ];

  const handleLevelChange = (value: string) => {
    setSelectedLevel(value);
  };

  const handleSetupChange = (value: string) => {
    setSelectedSetup(value);
  };

  const fetchData = () => {
    setLoading(true);
    const formatType = supportedOutput.join(",");
    if (formatType && formatName) {
      axios
        .get(`${apiUrl + view_lookup_dict}`, {
          params: {
            org_id: orgID,
            format_name: formatName,
            output_format_types: formatType,
          },
        })
        .then((response) => {
          processDictionaryData(response?.data);
          fetchSetupFile();
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
          setError("Something went wrong while fetching the lookup data.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchSetupFile = () => {
    const formatType = supportedOutput.join(",");
    axios
      .get(`${apiUrl + view_setup_file}`, {
        params: {
          format_types: formatType,
          format_name: formatName,
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        const results = response?.data;
        processSetupFileData(results);
        setData(results);
      })
      .catch((error) => {
        console.error("Error fetching setup file:", error);
        setError("Error fetching setup file.");
      });
  };

  const processDictionaryData = (data: any) => {
    const dictionary = getFirstAvailableDictionary(data);
    setBuyerDictionaryObject(dictionary);
    setSupplierDictionaryObject(dictionary);
  };

  const processSetupFileData = (data: any) => {
    const level = getSaveLevel(data);
    setSelectedLevel(level);
    const buyerSetup = getFileContent(data, "buyer_name_field_setup.json");
    const sellerSetup = getFileContent(data, "seller_name_field_setup.json");
    if (buyerSetup) {
      setActiveTab("buyer");
    } else {
      setActiveTab("seller");
    }
    if (buyerSetup) {
      const lookupType = getLookupType(buyerSetup);
      setSelectedSetup(lookupType === "Dictionary" ? "dictionary" : "Api");
      setBuyerLookupType(lookupType);
    }
    if (sellerSetup) {
      const lookupType = getLookupType(sellerSetup);
      setSelectedSetup(lookupType === "Dictionary" ? "dictionary" : "Api");
      setSupplierLookupType(lookupType);
    }
    setBuyerLookupDetails(getApiSetup(data, "buyer_name_field_setup.json"));
    setSupplierLookupDetails(getApiSetup(data, "seller_name_field_setup.json"));
  };

  const getSaveLevel = (data: any) => {
    if (!data || !data.result) {
      return null;
    }
    for (let format of supportedOutput) {
      const formatData = data.result[format];
      if (formatData) {
        const formatKey = Object.keys(formatData)[0];
        console.log("formatKey", formatKey);
        if (formatKey && formatData[formatKey]?.save_level) {
          return formatData[formatKey].save_level;
        }
      }
    }
    return null;
  };

  const getFileContent = (data: any, fileName: string) => {
    if (!data?.result) return undefined;
    for (let format of supportedOutput) {
      const formatData = data.result[format];
      const formatKey = Object.keys(formatData)[0];
      const fileContent = formatData[formatKey]?.files?.[fileName];
      if (fileContent) {
        return fileContent;
      }
    }
    return undefined;
  };

  // Return the lookupType
  const getLookupType = (fileContent: any) => {
    return fileContent?.content?.lookupType;
  };

  const getApiSetup = (data: any, fileName: string) => {
    if (!data?.result) return undefined;

    for (let format of supportedOutput) {
      const formatData = data.result[format];
      const formatKey = Object.keys(formatData)[0];
      const apiSetup =
        formatData[formatKey]?.files?.[fileName]?.content?.apiSetup;
      if (apiSetup) {
        return apiSetup;
      }
    }

    return undefined;
  };

  const getFirstAvailableDictionary = (data: any) => {
    if (supportedOutput.includes("json")) {
      const jsonDictionary = data?.files_content?.json?.content;
      if (jsonDictionary) return jsonDictionary;
    }

    if (supportedOutput.includes("csv")) {
      const csvDictionary = data?.files_content?.csv?.content;
      if (csvDictionary) return csvDictionary;
    }

    if (supportedOutput.includes("xml")) {
      const xmlDictionary = data?.files_content?.xml?.content;
      if (xmlDictionary) return xmlDictionary;
    }
    return "";
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
  }, [formatName, isEdit, supportedOutput]);

  const handleTabAction = (tab: string) => {
    setActiveTab(tab);
    if (tab === "buyer") {
      if (buyerLookupType !== "Dictionary" && isEdit) {
        setBuyerDictionaryObject("");
        setSelectedSetup("Api");
      } else {
        setSelectedSetup("dictionary");
      }
    } else {
      console.log("supplierLookupType", supplierLookupType);
      if (supplierLookupType !== "Dictionary" && isEdit) {
        setSupplierDictionaryObject("");
        setSelectedSetup("Api");
      } else {
        setSelectedSetup("dictionary");
      }
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold font-Inter mb-4 mt-4">
        Select the level for setting up lookup config:
      </h3>
      <RadioGroupComponent
        selectedValue={selectedLevel}
        handleValueChange={handleLevelChange}
        levels={levels}
      />
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="mt-8">
        <div className="tabs mb-4 flex ">
          <div
            className={`tab tab-bordered text-center flex-1 py-2 rounded-lg cursor-pointer ${
              activeTab === "buyer"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onClick={() => handleTabAction("buyer")}
          >
            Buyer Lookup Details
          </div>
          <div
            className={`tab tab-bordered text-center flex-1 py-2 rounded-lg cursor-pointer ${
              activeTab === "seller"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
            onClick={() => handleTabAction("seller")}
          >
            Supplier Lookup Details
          </div>
        </div>

        <div className="h-auto min-h-96 flex flex-col overflow-auto border-2 border-gray-400 rounded-lg">
          <div className="mt-2 p-4">
            <RadioGroupComponent
              selectedValue={selectedSetup}
              handleValueChange={handleSetupChange}
              levels={setUps}
            />
          </div>

          {activeTab === "buyer" && (
            <BuyerLookupDeatils
              selectedSetup={selectedSetup}
              buyerLookupDetails={buyerLookupDetails}
              setBuyerLookupDetails={setBuyerLookupDetails}
              setDictionaryObject={setDictionaryObject}
              dictionaryObject={dictionaryObject}
              formatName={formatName}
              selectedLevel={selectedLevel}
              supportedOutput={supportedOutput}
              buyerSupplier={buyerSupplier}
              isEdit={isEdit}
              setBuyerDictionaryObject={setBuyerDictionaryObject}
              buyerDictionaryObject={buyerDictionaryObject}
            />
          )}

          {activeTab === "seller" && (
            <SupplierLookupDeatils
              selectedSetup={selectedSetup}
              supplierLookupDetails={supplierLookupDetails}
              setSupplierLookupDetails={setSupplierLookupDetails}
              setDictionaryObject={setDictionaryObject}
              dictionaryObject={dictionaryObject}
              formatName={formatName}
              selectedLevel={selectedLevel}
              supportedOutput={supportedOutput}
              buyerSupplier={buyerSupplier}
              isEdit={isEdit}
              setSupplierDictionaryObject={setSupplierDictionaryObject}
              supplierDictionaryObject={supplierDictionaryObject}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigureLookup;
