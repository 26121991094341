import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import SupportedInvoice from "./SupportedInvoice";
import TakeDemo from "./TakeDemo";
import productIcon from "./../../assets/productIcon.svg";
import { useToast } from "../../components/Toast";
import axios from "axios";
import { useVisitCount } from "../../contexts/VisitCountContext";
import { GET_INVOICE_COUNT } from "../../utils/api-details/ApiList";
import { IconEye, IconProgressCheck } from "@tabler/icons-react";

const Home = () => {
  const navigate = useNavigate();
  const [isDemo, setIsDemo] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [count, setCount] = useState(0);
  const { showToast } = useToast();
  const visitCount = useVisitCount();
  const get_invoice_count = GET_INVOICE_COUNT;
  useEffect(() => {
    getInvoiceCount();
  }, []);

  const getInvoiceCount = () => {
    axios
      .get(`${apiUrl + get_invoice_count}`, {})
      .then((response) => {
        setCount(response?.data?.data?.data?.results[0]?.Invoice_count);
      })
      .catch((error) => {
        console.log(error);
        showToast("Failed to fetch data.Please try again later.", "error");
      });
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleDemo = () => {
    setIsDemo(true);
  };

  const handleCloseDemo = () => {
    setIsDemo(false);
  };

  return (
    <div className="min-h-screen p-4">
      <div className="w-full bg-white shadow-lg rounded-lg p-4 mb-4 card border-2">
        <div className="flex items-center justify-between">
          <img src={productIcon} alt="Invoicify" className="w-36 max-w-full" />
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={handleDemo}
              className="px-4 py-2 w-36 bg-blue-700 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
            >
              Take a Demo
            </button>
            <button
              type="button"
              onClick={handleLogin}
              className="px-4 py-2 w-36 border border-blue-700 text-blue-700 rounded-lg flex items-center justify-center"
            >
              Login
            </button>
          </div>
        </div>
      </div>

      <div className="bg-blue-100 w-full  h-80 shadow-lg rounded-lg p-6 mb-2 card">
        <Carousel />
      </div>

      <div className="flex space-x-4 justify-center">
        <div className="p-1 mb-2 w-96">
          <div className="bg-blue-600 text-white shadow-lg rounded-lg p-4 mb-4 hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <div className="flex items-center justify-center space-x-4">
              <div className="bg-blue-800 rounded-full p-3">
                <IconEye stroke={2} className="w-10 h-10" color="white" />
              </div>
              <div className="flex flex-col items-start">
                <span className="text-lg font-medium">TOTAL VISITS</span>
                <div className="flex items-center space-x-2">
                  <span className="text-xl font-bold">:</span>
                  <h1 className="text-xl sm:text-xl font-bold font-Inter">
                    {visitCount ?? 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Second Card */}
        <div className="p-1 mb-2  w-96">
          <div className="bg-blue-600 text-white shadow-lg rounded-lg p-4 mb-4 hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <div className="flex items-center justify-center space-x-4">
              <div className="bg-blue-800 rounded-full p-3">
                <IconProgressCheck
                  stroke={2}
                  className="w-10 h-10"
                  color="white"
                />
              </div>
              <div className="flex flex-col items-start">
                <span className="text-lg font-medium">INVOICES PROCESSED</span>
                <div className="flex items-center space-x-2">
                  <span className="text-xl font-bold">:</span>
                  <h1 className="text-xl sm:text-xl font-bold font-Inter">
                    {count ?? 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <SupportedInvoice isIconClick={false} />
      </div>
      {isDemo && <TakeDemo isOpen={true} onClose={handleCloseDemo} />}
    </div>
  );
};

export default Home;
