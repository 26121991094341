import { IconX } from "@tabler/icons-react";
import { useToast } from "../../../../components/Toast";

const FieldSection = ({
  format,
  fieldValue,
  setFieldValue,
  handleAddField,
  fields,
  setFieldValueFunction,
  handleRemoveField,
}: {
  format: string;
  fieldValue: string;
  setFieldValue: (value: string) => void;
  handleAddField: (format: string) => void;
  fields: string[];
  setFieldValueFunction: React.Dispatch<React.SetStateAction<string>>;
  handleRemoveField: (index: number, format: string) => void;
})  => {
    const { showToast } = useToast();
  
  // Function to handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.includes(" ")) {
      showToast("Spaces are replaced by underscores", "success");
    }
    // Replace spaces with underscores
    const updatedValue = newValue.replace(/\s+/g, "_");
    setFieldValue(updatedValue);
  };

  return (
  <div className="flex-1">
    <p className="mt-2 font-bold">Add fields for {format.toUpperCase()}</p>
    <div className="flex items-center mb-4">
      <input
        type="text"
        value={fieldValue}
        onChange={handleChange}
        placeholder="Enter field name"
        className="border border-gray-300 rounded-lg p-2 mr-2 w-64 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        onKeyDown={(e) => {
          if (e.key === "Enter") handleAddField(format);
        }}
      />
      <button
        onClick={() => handleAddField(format)}
        className="bg-blue-600 text-white rounded-lg py-2 px-4 transition-colors hover:bg-blue-700"
      >
        Add
      </button>
    </div>
    {fields.map((field, index) => (
      <div
        key={index}
        className="relative flex items-center justify-between p-2 w-80 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2"
      >
        <div className="flex items-center">
          <div className="ml-4 flex flex-col">
            <p className="text-gray-700">{field}</p>
          </div>
        </div>
        <IconX onClick={() => handleRemoveField(index, format)} stroke={1} />
      </div>
    ))}
  </div>
);
};

export default FieldSection;
