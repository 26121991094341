import { useRef, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import UploadInvoice from "./steps/uploadInvoice/UploadInvoice";
import AddFields from "./steps/addFields/AddFields";
import Mapping from "./steps/mapping/Mapping";
import ConfirmConfigure from "./steps/finalConfirmation/ConfirmConfigure";
import FormatName from "./steps/FormatName";
import axios from "axios";
import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../components/Toast";
import ConfirmationLookupConfig from "./steps/configureLookup/ConfirmLookUpConfig";
import ConfigureLookup from "./steps/configureLookup/ConfigureLookup";
import FormatConfirmation from "./steps/formatValidation/FormatConfirmation";
import FinalConiguration from "./steps/finalConfiguration/FinalConiguration";
import {
  ADD_DICTIONARY,
  ADD_FIELDS,
  ADD_FORMAT_HEADERS,
  MAPPING_FIELDS,
  MAPPING_FILE_STORED_PROC,
  VALIDATE_FORMAT_NAME,
  VIEW_FIELDS,
} from "../../utils/api-details/ApiList";
import Papa from "papaparse";
import ErrorPopup from "../../components/ErrorPopup";

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}
interface LookupDetails {
  url?: string;
  method?: string;
  username?: string;
  password?: string;
  requestBody?: {};
  pathParam?: {};
}
interface CSVData {
  headers: string[];
  data: string[][];
}
//steps for the stepper
const steps = [
  "Upload Invoice",
  "Format Name",
  "Add Fields",
  "Mapping Field",
  "Final Configuration",
  "Configure Lookup",
  "Confirm Configure",
];

// Map steps to components
const stepComponents = [
  UploadInvoice,
  FormatName,
  AddFields,
  Mapping,
  FinalConiguration,
  ConfigureLookup,
  ConfirmConfigure,
];

const StepperComponent = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentStep, setCurrentStep] = useState(0);
  const [formatName, setFormatName] = useState("");
  const [supportedOutput, setSupportedOutput] = useState<string[]>([]);
  const [isToggledForHeader, setIsToggledForHeader] = useState(false);
  const [fieldsList, setFieldsList] = useState<string[]>([]);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [jsonFields, setJsonFields] = useState<string[]>([]);
  const [xmlFields, setXmlFields] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const [jsonFiles, setJsonFiles] = useState<File[]>([]);
  const [xmlFiles, setXmlFiles] = useState<File[]>([]);
  const [uploadInvoiceFiles, setUploadInvoiceFiles] = useState<File[]>([]);
  const [mapping, setMapping] = useState<{ [key: string]: string }>({});
  const [csvMapping, setCsvMapping] = useState<{ [key: string]: string }>({});
  const [jsonMapping, setJsonMapping] = useState<{ [key: string]: string }>({});
  const [xmlMapping, setXmlMapping] = useState<{ [key: string]: string }>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [showNull, setShowNull] = useState("");
  const [value, setValue] = useState<string | null>(null);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [isError, setIsError] = useState(false);
  const [outputFields, setOutputFields] = useState<any[]>([]);
  const [outputCsvFields, setOutputCsvFields] = useState<any[]>([]);
  const [outputJsonFields, setOutputJsonFields] = useState<any[]>([]);
  const [outputXmlFields, setOutputXmlFields] = useState<any[]>([]);
  const [fileExtractedData, setFileExtractedData] = useState({});
  const [dictionaryMappingRequired, setDictionaryMappingRequired] =
    useState("");
  const navigate = useNavigate();
  const [buyerSupplier, setBuyerSupplier] = useState<BuyerSupplierData[]>([]);
  const { showToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [finalMappingRequried, setFinalMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [finalCsvMappingRequried, setFinalCsvMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [finalJsonMappingRequried, setFinalJsonMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [finalXmlMappingRequried, setFinalXmlMappingRequried] = useState<{
    [key: string]: string;
  }>({});
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const [isConfigureLookup, setIsConfigureLookup] = useState(false);
  const [activeTab, setActiveTab] = useState("buyer");
  const [dictionaryObject, setDictionaryObject] = useState("");
  const validate_format_name = VALIDATE_FORMAT_NAME;
  const mapping_file_stored_proc = MAPPING_FILE_STORED_PROC;
  const add_format_headers = ADD_FORMAT_HEADERS;
  const add_dictionary = ADD_DICTIONARY;
  const add_fields = ADD_FIELDS;
  const view_fields = VIEW_FIELDS;
  const mapping_fields = MAPPING_FIELDS;
  const [isErrorInStep, setIsErrorInStep] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileData, setFileData] = useState({});
  const [buyerDictionaryObject, setBuyerDictionaryObject] = useState("");
  const [buyerLookupDetails, setBuyerLookupDetails] = useState<LookupDetails>(
    {}
  );
  const [supplierDictionaryObject, setSupplierDictionaryObject] = useState("");
  const [supplierLookupDetails, setSupplierLookupDetails] =
    useState<LookupDetails>({});
  const prevFormatNameRef = useRef(formatName);

  const mandatoryFields = [
    "Invoice_No",
    "Invoice_Date",
    "Due_Date",
    "Total_Amount",
    "Total_Gst",
    "Payable_To",
    "Invoice_Currency",
    "Buyer_Name",
    "Commodity_Data",
    "Taxes_Table",
    "Charges_Table",
  ];

  const handleNext = async () => {
    const apiSteps = [1, 2, 3, 4]; // Steps requiring API calls
    if (apiSteps.includes(currentStep)) {
      await apiCallForStep(currentStep);
    }
    if (currentStep + 1 === 5) {
      setIsLookupModalOpen(true);
    }
    if (!isErrorInStep) {
      setCurrentStep(currentStep + 1);
    }
    setIsError(false);
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      if (!isConfigureLookup) {
        if (currentStep - 1 === 5) {
          setCurrentStep(4);
        }
      }
    }
    setIsErrorInStep(false);
  };

  const handleOk = () => {
    navigate("/dashboard");
  };

  const CurrentStepComponent = stepComponents[currentStep];

  const handleAddDict = async () => {
    const payload = {
      "BASIC FREIGHT": "BASIC FREIGHT",
      "Bunker surcharge NOS": "BAF NOS",
      "Bunker Recovery Adjustment Factor": "BUNKER RECOVERY",
      "Open top additional": "OPEN TOP ADD",
      "Flat rack additional": "FLAT RACK ADD",
      "Terminal handl ch origin": "THC ORIG",
      "Sealing service export": "SEALING EXPORT",
      "Export Documentation Fee": "EXPORT DOC FEE",
      "Doc Fee Special Equipment and/or Dangerous Cargo": "DOC FEE SPEC",
      "Container maintenance charge at origin": "CTR MAINT ORIG",
      "Export Declaration Surcharge": "EXP DECLARATION",
      "Ocean Carrier-Intl Ship & port Facility Security": "ISPS VESSEL",
      "Sensitive Cargo Contribution": "SENSITIVE CONTR",
      "Late Shipping Instruction submission": "LATE SI SUBMISS",
      "Terminal handl. ch destination": "THC DEST",
      "Cargo facility charge": "C. FACILITY CH",
      "Destinat.Terminal-Intl Ship&Port facility Security": "ISPS TERM DEST",
      "Bill of Lading Amendment Fee": "BL AMENDMENT",
      "Invoice Amendment": "INV AMENDMENT",
      "Cargo Value Serenity 2": "SERENITY 2",
    };
    await axios
      .post(`${apiUrl + add_dictionary}`, payload, {
        params: {
          org_id: orgID,
          user_id: userID,
          format_name: formatName,
        },
      })
      .then((response) => {
        console.log("response.data", response?.data?.data?.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFormatValidation = async () => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
      format_name: formatName,
    };

    // Check if formatName has changed compared to the previous one
    if (prevFormatNameRef.current !== formatName) {
      await axios
        .post(`${apiUrl + validate_format_name}`, payload, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          const isExit = response?.data?.data?.HFVALIDATION_FLAG;
          const msg = response?.data?.data?.HFERROR_MSG;
          if (isExit === 1) {
            showToast(msg, "error");
            setIsOpen(true);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // Update the previous format name to the current one after the API call
      prevFormatNameRef.current = formatName;
    }
  };

  function normalizeField(field: string) {
    // Handle Camel Case to Pascal Case with Underscore
    if (/[a-z][A-Z]/.test(field)) {
      return field
        .replace(/([a-z])([A-Z])/g, "$1_$2")
        .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
        .replace(/^([a-z])/g, (match) => match.toUpperCase());
    }
    // Handle Uppercase with Underscore to Pascal Case with Underscore
    if (field === field.toUpperCase() && /_/.test(field)) {
      return field
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join("_");
    }
    // Handle Snake Case and Lowercase with Underscore to Pascal Case with Underscore
    if (/_/.test(field)) {
      return field
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize the first letter of each word
        .join("_");
    }
    // If the input is already in Pascal Case (e.g., Invoice_No), return as is
    return field.charAt(0).toUpperCase() + field.slice(1);
  }

  const addFieldsAPI = async () => {
    handleAddDict();
    const formatType = supportedOutput.join(",");
    const fields = fieldsList.join(",");
    const formData = new FormData();
    const normalizedFieldsList = fieldsList.map(normalizeField);
    const normalizedMandatoryFields = mandatoryFields.map(normalizeField);

    // Step 1: Validate that mandatory fields are included in fieldsList
    if (fieldsList.length !== 0) {
      const missingMandatoryFields = normalizedMandatoryFields.filter(
        (field) => !normalizedFieldsList.includes(field)
      );
      if (missingMandatoryFields.length > 0) {
        console.error("Missing mandatory fields:", missingMandatoryFields);
        setErrorMessage(
          `Please include the following mandatory fields:\n${missingMandatoryFields.join(
            ", "
          )}`
        );
        setIsErrorModalOpen(true);
        return;
      }
    }

    // Step 2: Directly validate if CSV files contain mandatory fields in the headers
    for (const file of files) {
      try {
        const csvData = await parseCSVFile(file);
        const normalizedCsvHeaders = csvData.headers.map(normalizeField);
        // Check if mandatory fields are present in the CSV headers
        const missingFieldsInCSV = normalizedMandatoryFields.filter(
          (field) => !normalizedCsvHeaders.includes(field)
        );
        if (missingFieldsInCSV.length > 0) {
          console.error("CSV missing mandatory fields:", missingFieldsInCSV);
          setErrorMessage(
            `The CSV file is missing the following mandatory fields: ${missingFieldsInCSV.join(
              ", "
            )}`
          );
          setIsErrorModalOpen(true);
          return;
        }
      } catch (error) {
        console.error("Error parsing CSV file:", error);
        setErrorMessage("There was an error parsing the CSV file.");
        setIsErrorModalOpen(true);
        return;
      }
    }
    // Append valid files to FormData and other steps
    for (const file of files) {
      formData.append("file", file);
    }
    formData.append("fields", fieldsList.length > 0 ? fields : "");
    formData.append("format_type", formatType);
    formData.append("format_name", formatName);
    formData.append("orgid", orgID ?? "");
    formData.append("user_id", userID ?? "");

    if (isToggledForHeader) {
      try {
        const response = await axios.post(`${apiUrl + add_fields}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("Response from addFieldsAPI:", response);
        viewFieldsAPI();
      } catch (error) {
        console.error("Error in addFieldsAPI:", error);
      }
    } else {
      for (const type of supportedOutput) {
        const formatData = new FormData();
        switch (type) {
          case "json":
            if (jsonFields.length !== 0) {
              const normalizedJsonFieldsList = jsonFields?.map(normalizeField);
              // Check for missing mandatory fields in jsonFields
              const missingJsonFields = normalizedMandatoryFields?.filter(
                (field) => !normalizedJsonFieldsList?.includes(field)
              );
              if (missingJsonFields.length > 0) {
                setErrorMessage(
                  `The JSON field are missing mandatory fields: ${missingJsonFields.join(
                    ", "
                  )}`
                );
                setIsErrorModalOpen(true);
                return;
              }
            }
            for (const file of jsonFiles) {
              try {
                const jsonData = await parseCSVFile(file);
                const jsonHeaders = jsonData.headers;
                // Check if the JSON data has the necessary fields
                const missingFieldsInJson = normalizedMandatoryFields.filter(
                  (normalizedField) => {
                    return (
                      !jsonHeaders.includes(normalizedField) &&
                      !jsonHeaders.includes(normalizedField.toLowerCase()) &&
                      !jsonHeaders.includes(normalizedField.replace(/_/g, ""))
                    );
                  }
                );
                // If any mandatory fields are missing, show an error
                if (missingFieldsInJson.length > 0) {
                  setErrorMessage(
                    `The JSON files are missing mandatory fields: ${missingFieldsInJson.join(
                      ", "
                    )}`
                  );
                  setIsErrorModalOpen(true);
                  return;
                }
              } catch (error) {
                setErrorMessage("There was an error parsing the JSON file.");
                setIsErrorModalOpen(true);
                return;
              }
              // Append the JSON file to FormData after validation
              formatData.append("file", file);
            }
            const jsonFieldsString =
              jsonFields.length > 0 ? jsonFields.join(",") : "";
            formatData.append("fields", jsonFieldsString);
            break;

          case "csv":
            if (csvFields.length !== 0) {
              const normalizedCsvFieldsList = csvFields?.map(normalizeField);
              // Check for missing mandatory fields in csvFields
              const missingCsvFields = normalizedMandatoryFields?.filter(
                (field) => !normalizedCsvFieldsList?.includes(field)
              );
              if (missingCsvFields.length > 0) {
                setErrorMessage(
                  `The CSV fields are missing mandatory fields: ${missingCsvFields.join(
                    ", "
                  )}`
                );
                setIsErrorModalOpen(true);
                return;
              }
            }
            // Iterate over each CSV file
            for (const file of csvFiles) {
              try {
                const csvData = await parseCSVFile(file);
                const csvHeaders = csvData.headers;
                // Check if the mandatory fields  are present in the CSV headers
                const missingFieldsInCSV = normalizedMandatoryFields.filter(
                  (normalizedField) => {
                    return (
                      !csvHeaders.includes(normalizedField) &&
                      !csvHeaders.includes(normalizedField.toLowerCase()) &&
                      !csvHeaders.includes(normalizedField.replace(/_/g, ""))
                    );
                  }
                );
                // If any mandatory fields are missing, show an error
                if (missingFieldsInCSV.length > 0) {
                  setErrorMessage(
                    `The CSV file is missing the following mandatory fields: ${missingFieldsInCSV.join(
                      ", "
                    )}`
                  );
                  setIsErrorModalOpen(true);
                  return;
                }
              } catch (error) {
                setErrorMessage("There was an error parsing the CSV file.");
                setIsErrorModalOpen(true);
                return;
              }
              // Append the CSV file to FormData after validation
              formatData.append("file", file);
            }
            const csvFieldsString =
              csvFields.length > 0 ? csvFields.join(",") : "";
            formatData.append("fields", csvFieldsString);
            break;

          case "xml":
            if (xmlFields.length !== 0) {
              const normalizedXmlFieldsList = xmlFields?.map(normalizeField);
              // Check for missing mandatory fields in xmlFields
              const missingXmlFields = normalizedMandatoryFields.filter(
                (field) => !normalizedXmlFieldsList?.includes(field)
              );
              if (missingXmlFields.length > 0) {
                setErrorMessage(
                  `The XML fields are missing mandatory fields: ${missingXmlFields.join(
                    ", "
                  )}`
                );
                setIsErrorModalOpen(true);
                return;
              }
            }
            // Iterate over each XML file
            for (const file of xmlFiles) {
              try {
                const xmlData = await parseCSVFile(file);
                const xmlHeaders = xmlData.headers || [];
                // Check if the mandatory fields  are present in the XML headers/tags
                const missingFieldsInXML = normalizedMandatoryFields.filter(
                  (normalizedField) => {
                    return (
                      !xmlHeaders.includes(normalizedField) &&
                      !xmlHeaders.includes(normalizedField.toLowerCase()) &&
                      !xmlHeaders.includes(normalizedField.replace(/_/g, ""))
                    );
                  }
                );
                // If any mandatory fields are missing, show an error
                if (missingFieldsInXML.length > 0) {
                  setErrorMessage(
                    `The XML files are missing mandatory fields: ${missingFieldsInXML.join(
                      ", "
                    )}`
                  );
                  setIsErrorModalOpen(true);
                  return;
                }
              } catch (error) {
                setErrorMessage("There was an error parsing the XML file.");
                setIsErrorModalOpen(true);
                return;
              }
              // Append the XML file to FormData after validation
              formatData.append("file", file);
            }
            const xmlFieldsString =
              xmlFields.length > 0 ? xmlFields.join(",") : "";
            formatData.append("fields", xmlFieldsString);
            break;
          default:
            break;
        }
        formatData.append("format_type", type);
        formatData.append("format_name", formatName);
        formatData.append("orgid", orgID ?? "");
        formatData.append("user_id", userID ?? "");
        // Step 6: Send the FormData for each format via API
        try {
          const response = await axios.post(
            `${apiUrl + add_fields}`,
            formatData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(`Response for ${type} format:`, response);
          viewFieldsAPI();
        } catch (error) {
          console.error(`Error in addFieldsAPI for ${type}:`, error);
        }
      }
    }
  };

  const parseCSVFile = (file: File): Promise<CSVData> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (result) => {
          const headers = result.data[0] as string[];
          resolve({ headers, data: result.data as string[][] });
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const viewFieldsAPI = async () => {
    try {
      for (const formatType of supportedOutput) {
        const response = await axios.get(`${apiUrl + view_fields}`, {
          params: {
            format_type: formatType,
            format_name: formatName,
            orgid: orgID,
            user_id: userID,
          },
        });
        const fields = response?.data?.fields;
        if (formatType === "csv") {
          setOutputCsvFields(fields);
        } else if (formatType === "json") {
          setOutputJsonFields(fields);
        } else if (formatType === "xml") {
          setOutputXmlFields(fields);
        }
        if (isToggledForHeader) {
          setOutputFields(fields);
        }
      }
    } catch (error) {
      console.error("Error in viewFieldsAPI:", error);
    }
  };

  const mappingFieldsAPI = async () => {
    if (isToggledForHeader) {
      const updatedMapping = { ...mapping };
      outputFields?.forEach((field) => {
        if (!(field in updatedMapping)) {
          updatedMapping[field] = "";
        }
      });
      const formatType = supportedOutput.join(",");
      const payload = {
        data_dict: updatedMapping,
        data_setting_dict: {
          skip_null: showNull === "Yes" ? "True" : "False",
          alias: showNull === "Yes" ? value : "-",
          dictionary_mapping_required: dictionaryMappingRequired,
        },
      };
      try {
        const response = await axios.post(
          `${apiUrl + mapping_fields}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              format_type: formatType,
              format_name: formatName,
              org_id: orgID,
              user_id: userID,
            },
          }
        );
      } catch (error) {
        console.error("Error in mappingFieldsAPI:", error);
      }
    } else {
      for (const formatType of supportedOutput) {
        let payload;
        switch (formatType.toLowerCase()) {
          case "csv":
            const updatedCsvMapping = { ...csvMapping };
            Object.keys(updatedCsvMapping).forEach((key) => {
              if (!outputCsvFields.includes(key)) {
                delete updatedCsvMapping[key];
              }
            });
            outputCsvFields.forEach((outputField) => {
              if (!(outputField in updatedCsvMapping)) {
                updatedCsvMapping[outputField] = "";
              }
            });

            payload = {
              data_dict: updatedCsvMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          case "json":
            const updatedJsonMapping = { ...jsonMapping };
            Object.keys(updatedJsonMapping).forEach((key) => {
              if (!outputJsonFields.includes(key)) {
                delete updatedJsonMapping[key];
              }
            });
            outputJsonFields.forEach((outputField) => {
              if (!(outputField in updatedJsonMapping)) {
                updatedJsonMapping[outputField] = "";
              }
            });
            payload = {
              data_dict: updatedJsonMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          case "xml":
            const updatedXmlMapping = { ...xmlMapping };
            Object.keys(updatedXmlMapping).forEach((key) => {
              if (!outputXmlFields.includes(key)) {
                delete updatedXmlMapping[key];
              }
            });
            outputXmlFields.forEach((outputField) => {
              if (!(outputField in updatedXmlMapping)) {
                updatedXmlMapping[outputField] = "";
              }
            });
            payload = {
              data_dict: updatedXmlMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          default:
            break;
        }
        try {
          const response = await axios.post(
            `${apiUrl + mapping_fields}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                format_type: formatType,
                format_name: formatName,
                org_id: orgID,
                user_id: userID,
              },
            }
          );
          // console.log(`Response from mappingFieldsAPI:`, response);
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
          showToast("Something went wrong", "error");
        }
      }
    }
    formatHeaders();
  };

  const mappingStore = async () => {
    if (isToggledForHeader) {
      const formatType = supportedOutput.join(",");
      try {
        const response = await axios.post(
          `${apiUrl + mapping_file_stored_proc}`,
          finalMappingRequried,
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              orgid: orgID,
              user_id: userID,
              format_name: formatName,
              format_type: formatType,
            },
          }
        );
        // console.log("Response from mappingFieldsAPI:", response);
      } catch (error) {
        console.error("Error in mappingFieldsAPI:", error);
      }
    } else {
      for (const formatType of supportedOutput) {
        let payload;
        switch (formatType.toLowerCase()) {
          case "csv":
            payload = finalCsvMappingRequried;
            break;
          case "json":
            payload = finalJsonMappingRequried;
            break;
          case "xml":
            payload = finalXmlMappingRequried;
            break;
          default:
            break;
        }
        try {
          await axios.post(`${apiUrl + mapping_file_stored_proc}`, payload, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              orgid: orgID,
              user_id: userID,
              format_name: formatName,
              format_type: formatType,
            },
          });
          setFinalCsvMappingRequried({});
          setFinalJsonMappingRequried({});
          setFinalXmlMappingRequried({});
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
        }
      }
    }
  };

  const formatHeaders = async () => {
    const payload = {
      headers: fileExtractedData,
    };
    await axios
      .post(`${apiUrl + add_format_headers}`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          org_id: orgID,
          user_id: userID,
          format_name: formatName,
        },
      })
      .then((response) => {
        // console.log("response.data", response?.data?.data?.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const apiCallForStep = async (stepIndex: number) => {
    if (!isErrorInStep) {
      switch (stepIndex) {
        case 1:
          return handleFormatValidation();
        case 2:
          return addFieldsAPI();
        case 3:
          return mappingFieldsAPI();
        case 4:
          return mappingStore();
        default:
          throw new Error("No API defined for this step.");
      }
    }
  };

  const handleCloseFormatConfirmation = () => {
    setIsOpen(false);
  };

  const handleRename = () => {
    setCurrentStep(currentStep - 1);
    setIsOpen(false);
  };

  const handleCloseLookup = () => {
    setIsLookupModalOpen(false);
  };

  const handlePopupRequriedFields = () => {
    setIsErrorModalOpen(false);
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="bg-blue-50">
      <div className="w-full p-4 border-2 border-gray-300 rounded-lg bg-grey-50  flex flex-col items-center pb-10">
        <div className="w-full px-12">
          <div className="p-0 ">
            <Stepper activeStep={currentStep}>
              {steps.map((step, index) => {
                const isActive = index === currentStep;
                const isCompleted = index < currentStep;
                return (
                  <Step key={index} label={step}>
                    <div
                      className={`${isCompleted ? "bg-blue-500 text-white" : ""}
                                ${
                                  isActive
                                    ? "bg-blue-200  border-2 border-blue-500 text-black"
                                    : ""
                                }
                    ${
                      !isActive && !isCompleted
                        ? "bg-gray-300 text-gray-600"
                        : ""
                    }
                    w-8 h-8 rounded-full flex justify-center items-center
                    text-sm font-semibold
                  `}
                    >
                      {isCompleted ? (
                        <IconCheck
                          stroke={2}
                          color="white"
                          className="text-xl"
                        />
                      ) : (
                        <span className="text-base">{index + 1}</span>
                      )}
                    </div>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div
            className={`min-h-96 max-h-auto  p-4 border border-gray-300 rounded-lg bg-sky-50`}
          >
            <CurrentStepComponent
              setFormatName={setFormatName}
              setSupportedOutput={setSupportedOutput}
              formatName={formatName}
              supportedOutput={supportedOutput}
              setIsToggledForHeader={setIsToggledForHeader}
              isToggledForHeader={isToggledForHeader}
              fieldsList={fieldsList}
              setFieldsList={setFieldsList}
              files={files}
              setFiles={setFiles}
              uploadInvoicefiles={uploadInvoiceFiles}
              setUploadInvoiceFiles={setUploadInvoiceFiles}
              setMapping={setMapping}
              mapping={mapping}
              outputFields={outputFields}
              setHasChanges={setHasChanges}
              showNull={showNull}
              setShowNull={setShowNull}
              value={value}
              setValue={setValue}
              setCsvFiles={setCsvFiles}
              csvFiles={csvFiles}
              setJsonFiles={setJsonFiles}
              jsonFiles={jsonFiles}
              setXmlFiles={setXmlFiles}
              xmlFiles={xmlFiles}
              csvFields={csvFields}
              setCsvFields={setCsvFields}
              jsonFields={jsonFields}
              setJsonFields={setJsonFields}
              xmlFields={xmlFields}
              setXmlFields={setXmlFields}
              isError={isError}
              outputCsvFields={outputCsvFields}
              outputJsonFields={outputJsonFields}
              outputXmlFields={outputXmlFields}
              csvMapping={csvMapping}
              setCsvMapping={setCsvMapping}
              jsonMapping={jsonMapping}
              setJsonMapping={setJsonMapping}
              xmlMapping={xmlMapping}
              setXmlMapping={setXmlMapping}
              fileExtractedData={fileExtractedData}
              setFileExtractedData={setFileExtractedData}
              setDictionaryMappingRequired={setDictionaryMappingRequired}
              buyerSupplier={buyerSupplier}
              setBuyerSupplier={setBuyerSupplier}
              setFinalMappingRequried={setFinalMappingRequried}
              setFinalCsvMappingRequried={setFinalCsvMappingRequried}
              setFinalJsonMappingRequried={setFinalJsonMappingRequried}
              setFinalXmlMappingRequried={setFinalXmlMappingRequried}
              finalMappingRequried={finalMappingRequried}
              isCreate={true}
              isConfigureLookup={isConfigureLookup}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setDictionaryObject={setDictionaryObject}
              dictionaryObject={dictionaryObject}
              setIsErrorInStep={setIsErrorInStep}
              setFileData={setFileData}
              fileData={fileData}
              buyerDictionaryObject={buyerDictionaryObject}
              setBuyerDictionaryObject={setBuyerDictionaryObject}
              buyerLookupDetails={buyerLookupDetails}
              setBuyerLookupDetails={setBuyerLookupDetails}
              supplierDictionaryObject={supplierDictionaryObject}
              setSupplierDictionaryObject={setSupplierDictionaryObject}
              supplierLookupDetails={supplierLookupDetails}
              setSupplierLookupDetails={setSupplierLookupDetails}
            />
          </div>
        </div>
        <div className="flex space-x-4 mt-6">
          <button
            onClick={handlePrev}
            disabled={currentStep === 0}
            className={`px-4 py-2 rounded ${
              currentStep === 0
                ? "bg-gray-300 text-gray-500"
                : "bg-blue-600 text-white"
            }`}
          >
            Previous
          </button>

          <button
            onClick={currentStep === steps.length - 1 ? handleOk : handleNext}
            disabled={currentStep === steps.length - 1 && !formatName}
            className={`px-4 py-2 rounded ${
              currentStep === steps.length - 1
                ? "bg-blue-600 text-white"
                : "bg-blue-600 text-white"
            }`}
          >
            {currentStep === steps.length - 1 ? "OK" : "Save & Continue"}
          </button>
        </div>
      </div>
      {isOpen && (
        <FormatConfirmation
          open={isOpen}
          onClose={handleCloseFormatConfirmation}
          handleRename={handleRename}
        />
      )}
      {isLookupModalOpen && (
        <ConfirmationLookupConfig
          isOpen={isLookupModalOpen}
          onClose={handleCloseLookup}
          setIsConfigureLookup={setIsConfigureLookup}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}

      {isErrorModalOpen && (
        <ErrorPopup
          open={isErrorModalOpen}
          onClose={handlePopupRequriedFields}
          errorMessage={errorMessage}
          errorTitle="Mandatory Fields"
        />
      )}
    </div>
  );
};

export default StepperComponent;
