import Modal from "./Modal";

interface ErrorPopupProps {
  open: boolean;
  onClose: () => void;
  errorMessage: string;
  onRetry?: () => void;
  isRetryEnabled?: boolean;
  errorTitle?: string;
}
const ErrorPopup: React.FC<ErrorPopupProps> = ({
  open,
  onClose,
  errorMessage,
  onRetry,
  isRetryEnabled = false,
  errorTitle,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleRetry = () => {
    if (onRetry) {
      onRetry();
    }
    onClose();
  };

  return (
    <div className="z-50">
      <Modal
        isOpen={open}
        onClose={onClose}
        title={errorTitle ? errorTitle : "Error"}
        showCancelButton={false}
        size="w-5/12"
        isCrossIcon={true}
      >
        <div className="max-h-96">
          <div className="text-red-600 text-lg font-semibold mb-4">
            {errorMessage}{" "}
          </div>
          <div className="flex space-x-4 mt-6 justify-center">
            <button
              onClick={handleClose}
              className="px-4 py-2 w-28 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Ok
            </button>

            {isRetryEnabled && (
              <button
                onClick={handleRetry}
                className={`px-4 py-2 rounded bg-blue-600 text-white w-36`}
              >
                {"Retry"}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorPopup;
