import {
  IconCurrencyRupee,
  IconEye,
  IconEyeOff,
  IconFileText,
  IconProgressCheck,
  IconUser,
} from "@tabler/icons-react";
import Carousel from "../../components/Carousel";
import SupportedInvoice from "../home/SupportedInvoice";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useToast } from "../../components/Toast";
import {
  GET_INVOICE_AMOUNT_BY_FORMAT_NAME,
  GET_INVOICE_AMOUNT_BY_ORGID,
  GET_INVOICE_AMOUNT_BY_VENDOR_NAME,
  GET_INVOICE_COUNT_BY_ORGID,
  GetInvoiceAndFormatCounts,
} from "../../utils/api-details/ApiList";
import TableComponent from "../../components/TableComponent";

type StatsData = {
  FVENDOR_NAME: string;
  FORMAT_NAME: string;
  Total_taxable_amount: number;
  Total_tax_amount: number;
  Total_invoice_amount: number;
};

const DashboardPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const [count, setCount] = useState(0);
  const get_invoice_count_by_orgid = GET_INVOICE_COUNT_BY_ORGID;
  const userID = sessionStorage.getItem("userId");
  const { showToast } = useToast();
  const [venderData, setVenderData] = useState<StatsData[]>([]);
  const [formatData, setFormatData] = useState<StatsData[]>([]);
  const [orgData, setOrgData] = useState("");
  const [getInvoiceAndFormatCounts, setGetInvoiceAndFormatCounts] =
    useState("");
  const get_invoice_amount_by_vendor_name = GET_INVOICE_AMOUNT_BY_VENDOR_NAME;
  const get_invoice_amount_by_format_name = GET_INVOICE_AMOUNT_BY_FORMAT_NAME;
  const get_invoice_amount_by_org = GET_INVOICE_AMOUNT_BY_ORGID;
  const get_invoice_and_format_counts = GetInvoiceAndFormatCounts;
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    getInvoiceCount();
    getVendorDetails(get_invoice_amount_by_vendor_name);
    getFormatDetails(get_invoice_amount_by_format_name);
    getOrgDetails(get_invoice_amount_by_org);
    getInvoiceAndFormatCount();
  },[]);

  const venderColumns = [
    {
      name: "Vender Name",
      selector: (row: StatsData) => row.FVENDOR_NAME,
      sortable: true,
      filterable: true,
    },
    {
      name: "Total Taxable Amount",
      selector: (row: StatsData) => row.Total_taxable_amount,
      sortable: true,
    },
    {
      name: "Total Tax Amount",
      selector: (row: StatsData) => row.Total_tax_amount,
      sortable: true,
    },
    {
      name: "Total Invoice Amount",
      selector: (row: StatsData) => row.Total_invoice_amount,
      sortable: false,
    },
  ];

  const formatColumns = [
    {
      name: "Format Name",
      selector: (row: StatsData) => row.FORMAT_NAME,
      sortable: true,
      filterable: true,
    },
    {
      name: "Total Taxable Amount",
      selector: (row: StatsData) => row.Total_taxable_amount,
      sortable: true,
    },
    {
      name: "Total Tax Amount",
      selector: (row: StatsData) => row.Total_tax_amount,
      sortable: true,
    },
    {
      name: "Total Invoice Amount",
      selector: (row: StatsData) => row.Total_invoice_amount,
      sortable: false,
    },
  ];

  const getInvoiceCount = useCallback(() => {
    axios
      .get(`${apiUrl + get_invoice_count_by_orgid}`, {
        params: { org_id: orgID, user_id: userID },
      })
      .then((response) => {
        const count =
          response?.data?.data?.data?.results[0].Invoice_count_orgid;
        setCount(count);
      })
      .catch((error) => {
        console.error(error);
        showToast(
          `Failed to fetch invoice count  ${
            error?.response?.data?.message || "Please try again later."
          }`,
          "error"
        );
      });
  }, [apiUrl, get_invoice_count_by_orgid, orgID, userID, showToast]);

  const getVendorDetails = (type: string) => {
    axios
      .get(`${apiUrl + type}`, {
        params: {
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        const data = response?.data?.data?.data?.results;
        setVenderData(data);
      })
      .catch((error) => {
        console.log(error);
        showToast(
          "Failed to fetch vendor details. Please try again later.",
          "error"
        );
      });
  };

  const getFormatDetails = (type: string) => {
    axios
      .get(`${apiUrl + type}`, {
        params: {
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        console.log("response", response?.data?.data?.data?.results);
        setFormatData(response?.data?.data?.data?.results);
      })
      .catch((error) => {
        console.log(error);
        showToast(
          "Failed to fetch format details. Please try again later.",
          "error"
        );
      });
  };

  const getOrgDetails = (type: string) => {
    axios
      .get(`${apiUrl + type}`, {
        params: {
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        const amount =
          response?.data?.data?.data?.results[0]?.Total_invoice_amount ?? 0;
        setOrgData(amount);
      })
      .catch((error) => {
        console.log(error);
        showToast(
          "Failed to fetch org details.Please try again later.",
          "error"
        );
      });
  };

  const getInvoiceAndFormatCount = () => {
    axios
      .post(
        `${apiUrl + get_invoice_and_format_counts}`,
        {},
        {
          params: {
            org_id: orgID,
          },
        }
      )
      .then((response) => {
        setGetInvoiceAndFormatCounts(
          response?.data?.data?.p_InvoiceFormatCount
        );
      })
      .catch((error) => {
        console.log(error);
        showToast("Failed to fetch data.Please try again later.", "error");
      });
  };

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  return (
    <div className="p-4">
      <div className="flex space-x-4 justify-center flex-wrap">
        {/* Invoice Processed Card */}
        <div className="w-72">
          <div className="bg-blue-100 hover:bg-blue-500 hover:text-white shadow-lg rounded-lg p-4 mb-4 hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <div className="flex justify-between items-center">
              <div className="bg-pink-500 rounded-full p-3">
                <IconProgressCheck
                  stroke={2}
                  className="w-10 h-10"
                  color="white"
                />
              </div>
              <div className="flex flex-col items-end mt-2">
                <span className="text-sm sm:text-base font-medium">
                  INVOICES PROCESSED
                </span>
                <div className="flex items-center space-x-2">
                  <h1 className="text-xl sm:text-xl font-bold font-Inter">
                    {count ?? 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Total Vendors Card */}
        <div className="w-72">
          <div className="bg-blue-100 hover:bg-blue-500 hover:text-white shadow-lg rounded-lg p-4 mb-4 hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <div className="flex justify-between items-center">
              <div className="bg-orange-500 rounded-full p-3">
                <IconUser stroke={2} className="w-10 h-10" color="white" />
              </div>
              <div className="flex flex-col items-end mt-2">
                <span className="text-sm sm:text-base font-medium">
                  VENDORS
                </span>
                <div className="flex items-center space-x-2">
                  <h1 className="text-xl sm:text-xl font-bold font-Inter">
                    {venderData.length ?? 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Total Formats Card */}
        <div className="w-72">
          <div className="bg-blue-100 hover:bg-blue-500 hover:text-white shadow-lg rounded-lg p-4 mb-4 hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <div className="flex justify-between items-center">
              <div className="bg-sky-500 rounded-full p-3">
                <IconFileText stroke={2} className="w-10 h-10" color="white" />
              </div>
              <div className="flex flex-col items-end mt-2">
                <span className="text-sm sm:text-base font-medium">
                  FORMATS
                </span>
                <div className="flex items-center space-x-2">
                  <h1 className="text-xl sm:text-xl font-bold font-Inter">
                    {getInvoiceAndFormatCounts ?? 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Total Invoice Amount Card */}
        <div className="w-72">
          <div className="bg-blue-100 hover:bg-blue-500 hover:text-white shadow-lg rounded-lg p-4 mb-4 hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <div className="flex justify-between items-center">
              <div className="bg-green-500 rounded-full p-3">
                <IconCurrencyRupee
                  stroke={2}
                  className="w-10 h-10"
                  color="white"
                />
              </div>
              <div className="flex flex-col items-end mt-2">
                <span className="text-sm sm:text-base font-medium">
                  INVOICE AMOUNT
                </span>
                <div className="flex items-center space-x-2">
                  <h1 className="text-xl sm:text-xl font-bold font-Inter">
                    {isVisible ? orgData : "****"}
                  </h1>
                </div>
              </div>
              <div
                onClick={toggleVisibility}
                className="cursor-pointer text-xl text-gray-600 mb-10"
              >
                {isVisible ? <IconEye stroke={2} /> : <IconEyeOff stroke={2} />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-100 w-full  h-80 shadow-lg rounded-lg p-6 mb-2 card mt-6">
        <Carousel />
      </div>

      <div className="flex space-x-4 justify-between">
        <div
          className="flex-1 overflow-x-auto"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}
        >
          <div className="!p-0">
            <TableComponent
              title={"Vendor"}
              columns={venderColumns}
              rows={venderData.slice(0, 5)}
              pagination={false}
              hasFilterableColumns={false}
            />
          </div>
        </div>

        <div
          className="flex-1 overflow-x-auto"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}
        >
          <TableComponent
            title={"Format"}
            columns={formatColumns}
            rows={formatData.slice(0, 5)}
            pagination={false}
            hasFilterableColumns={false}
          />
        </div>
      </div>

      <SupportedInvoice isIconClick={true} />
    </div>
  );
};

export default DashboardPage;
