import { useEffect, useState } from "react";
import AceEditor from "react-ace";
import "brace/mode/xml";
import "brace/theme/github";
import axios from "axios";
import { EDIT_XML, VIEW_XML } from "../../../utils/api-details/ApiList";
import { useLocation, useNavigate } from "react-router-dom";

export const EditXml = () => {
  const location = useLocation();
  const {
    fileName,
    selectedOption,
    format_name,
    orgID,
    userID,
    selectedVersion,
    uploadedFileName,
  } = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL;
  const [xmlData, setXmlData] = useState<string>("");
  const [editableData, setEditableData] = useState<string>("");
  const [version, setVersion] = useState<string>("");
  const view_xml = VIEW_XML;
  const edit_xml = EDIT_XML;
  const navigate = useNavigate();

  const updatedFile = () => {
    const name = fileName + ".xml";
    axios
      .get(`${apiUrl + view_xml}/${name}`, {
        params: {
          format_name: format_name,
          version: selectedVersion === "" ? 0 : selectedVersion,
          type: version === "" ? "appended" : version,
          orgid: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        setXmlData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedOption === "original_xml") {
      setVersion("original");
    } else {
      setVersion("appended");
    }
    updatedFile();
  }, [selectedOption]);

  useEffect(() => {
    setEditableData(xmlData);
  }, [xmlData]);

  const onChange = (newValue: string) => {
    setEditableData(newValue);
  };

  const handleSaveClick = () => {
    const xml = editableData?.replace(/\n/g, "").replace(/\s+/g, " ")?.trim();
    axios
      .patch(`${apiUrl + edit_xml}/${fileName + ".xml"}`, xml, {
        headers: {
          "Content-Type": "application/xml",
        },
        params: {
          format_name: format_name,
          selected_version: selectedVersion === "" ? 0 : selectedVersion,
          type: version,
          orgid: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        console.log(response);
        updatedFile();
      })
      .catch((error) => {
        console.log(error);
      });

    navigate("/upload_file/viewXml", {
      state: {
        isViewModalOpen: true,
        documentName: fileName,
        format_name,
        back: true,
        uploadedFileName,
        editedData: editableData,
      },
    });
  };

  const handleCancelClick = () => {
    navigate("/upload_file/viewXml", {
      state: {
        isViewModalOpen: true,
        documentName: fileName,
        format_name,
        back: true,
        uploadedFileName,
      },
    });
  };

  const formatXml = (xml: string) => {
    return xml.replace(/</g, "\n<").replace(/\n\n/g, "\n");
  };

  return (
    <div className="p-4">
      <div className="flex flex-col">
        <div className="mt-auto flex justify-end space-x-4">
          <button
            type="button"
            className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
            onClick={handleSaveClick}
          >
            Save
          </button>
          <button
            type="button"
            className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className=" w-full items-center justify-between mt-6 bg-blue-50 border-2 border-gray-300">
        <AceEditor
          mode="xml"
          theme="github"
          onChange={onChange}
          editorProps={{ $blockScrolling: true }}
          value={formatXml(editableData)}
          fontSize={14}
          lineHeight={19}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          setOptions={{
            showGutter: true,
            highlightActiveLine: true,
            fontSize: 14,
          }}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default EditXml;
