import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { ToastProvider } from "./components/Toast";
import { VisitCountProvider } from "./contexts/VisitCountContext";
import Navbar from "./pages/navbar/Navbar";
import AppRoutes from "./routes/AppRoutes";

const App = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const location = useLocation();
  const hideNavbarRoutes = ["/", "/login", "/forgotPassword", "/resetPassword"];
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  return (
    <I18nextProvider i18n={i18next}>
      <ToastProvider>
        <VisitCountProvider>
          <div className="flex h-screen relative">
            {!hideNavbarRoutes.includes(location.pathname) && (
              <div
                ref={sidebarRef}
                className={`fixed top-0 left-0  w-full transition-all duration-300 ${
                  isSidebarOpen ? "w-64" : "w-0"
                } z-50`}
              >
                <Navbar
                  setSidebarOpen={setSidebarOpen}
                  isSidebarOpen={isSidebarOpen}
                />
              </div>
            )}

            {/* Main content area */}
            <div
              className={`flex-1 overflow-auto transition-all duration-300 ${
                isSidebarOpen
                  ? "ml-64"
                  : hideNavbarRoutes.includes(location.pathname)
                  ? "ml-0"
                  : "ml-20"
              } ${
                hideNavbarRoutes.includes(location.pathname) ? "mt-0" : "mt-24"
              }`}
            >
              <div className="h-full">
                <AppRoutes />
              </div>
            </div>
          </div>
        </VisitCountProvider>
      </ToastProvider>
    </I18nextProvider>
  );
};

export default App;
