import Modal from "../../../../components/Modal";

interface ConfirmationLookupModalProps {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
  setIsConfigureLookup: (value: boolean) => void;
  setCurrentStep: (value: number) => void;
  currentStep: number;
}

const ConfirmationLookupConfig: React.FC<ConfirmationLookupModalProps> = ({
  isOpen,
  onClose,
  setIsConfigureLookup,
  setCurrentStep,
  currentStep,
  isEdit,
}) => {

  const handleYes = () => {
    setIsConfigureLookup(true);
    onClose();
  };
  const handleNo = () => {
    setIsConfigureLookup(false);
    setCurrentStep(currentStep + 1);
    onClose();
  };

  return (
    <div className="z-50">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Configure Lookup"
        showCancelButton={false}
        size="w-5/12"
        isCrossIcon={false}
      >
        <div className="max-h-96">
          {!isEdit
            ? "Would you like to enable any lookup for the Buyer and Seller output fields?"
            : "Would you like to update any lookup for the Buyer and Seller output fields?"}
          <div className="flex space-x-4 mt-6 justify-center">
            <button
              onClick={handleYes}
              className={`px-4 py-2 rounded bg-blue-600 text-white w-36`}
            >
              Yes
            </button>
            <button
              onClick={handleNo}
              className={`px-4 py-2 rounded bg-blue-600 text-white w-36`}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationLookupConfig;
