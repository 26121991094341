import React, { useState } from "react";
import DataTable from "react-data-table-component";

type DataTableProps<T> = {
  title?: string;
  columns: {
    name: string;
    selector?: (row: T) => any;
    sortable?: boolean;
    filterable?: boolean;
  }[];
  rows: T[];
  selectableRows?: boolean;
  onSelectedRowsChange?: (state: any) => void;
  pagination?: boolean;
  highlightOnHover?: boolean;
  striped?: boolean;
  persistTableHead?: boolean;
  hasFilterableColumns?: boolean;
};

const TableComponent = <T extends {}>({
  title,
  columns,
  rows,
  selectableRows = false,
  onSelectedRowsChange,
  pagination = true,
  highlightOnHover = true,
  striped = true,
  persistTableHead = true,
  hasFilterableColumns = true,
}: DataTableProps<T>) => {
  const [filter, setFilter] = useState<string>("");

  // Handle changes in the global filter
  const filteredRows = Array.isArray(rows)
    ? rows.filter((row) => {
        const matchesGlobalFilter = filter
          ? columns.some((column) => {
              const cellValue = column.selector ? column.selector(row) : "";
              if (typeof cellValue === "string") {
                return cellValue.toLowerCase().includes(filter.toLowerCase());
              } else if (typeof cellValue === "number") {
                return cellValue.toString().includes(filter);
              }
              return false;
            })
          : true;

        return matchesGlobalFilter;
      })
    : [];

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  //  hasFilterableColumns = columns.some((column) => column.filterable);
  return (
    <div className="container mx-auto p-6 space-y-6">
      <div className="flex items-center justify-between mt-2">
        <h1 className="text-blue-700 text-lg font-bold">{title}</h1>
        {/* Global filter input */}
        {hasFilterableColumns && (
          <input
            type="text"
            className="w-full sm:w-96 p-4 border-2 border-blue-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search..."
            value={filter}
            onChange={handleFilterChange}
          />
        )}
      </div>
      <div className="overflow-x-auto rounded-lg shadow-lg bg-white border border-gray-200">
        <DataTable
          columns={columns}
          data={filteredRows}
          selectableRows={selectableRows}
          onSelectedRowsChange={onSelectedRowsChange}
          pagination={pagination}
          highlightOnHover={highlightOnHover}
          striped={striped}
          persistTableHead={persistTableHead}
          className="min-w-full text-sm text-gray-700"
          customStyles={{
            rows: {
              style: {
                minHeight: "48px",
                fontSize: "14px",
              },
            },
            headCells: {
              style: {
                backgroundColor: "#3b82f6",
                fontWeight: "600",
                fontSize: "16px",
                color: "white",
                borderBottomWidth: "2px",
                borderBottomColor: "#388E3C",
                padding: "12px",
                textTransform: "uppercase",
              },
            },
            cells: {
              style: {
                padding: "12px",
                borderBottom: "1px solid #e5e7eb",
                backgroundColor: "#f9f9f9",
              },
            },
            pagination: {
              style: {
                backgroundColor: "#fafafa",
              },
            },
            // highlightOnHover: {
            //   style: {
            //     backgroundColor: "#f1f9f6",
            //   },
            // },
          }}
        />
      </div>
    </div>
  );
};

export default TableComponent;
