import { useEffect, useState } from "react";
import pdf from "./../../assets/pdf-icon.png";
import axios from "axios";
import {
  BATCH_CONVERSION,
  GET_FORMAT_NAME,
  INVOICE_TO_CSV,
  INVOICE_TO_JSON,
  INVOICE_TO_XML,
} from "../../utils/api-details/ApiList";
import { useToast } from "../../components/Toast";
import { IconArrowBigLeftFilled } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import FailedFilesWarning from "./FailedFilesWarning";
import WarningPopup from "../../components/WarningPopup";
import Spinner from "../../components/Spinner";

interface errorFileds {
  error: string;
  filename: string;
}

const UploadedFileDetails = () => {
  const location = useLocation();
  const {
    // isFileDetailsModalOpen,
    fileName,
    selectedOption,
    setFileName,
    errorMessages,
    isError,
    uploadErrorFor,
  } = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL;
  const [supportedOutputTypes, setSupportedOutputTypes] = useState<string[]>(
    []
  );
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const get_format_name = GET_FORMAT_NAME;
  const { showToast } = useToast();
  const navigate = useNavigate();
  const invoice_to_json = INVOICE_TO_JSON;
  const invoice_to_csv = INVOICE_TO_CSV;
  const invoice_to_xml = INVOICE_TO_XML;
  const apiUrlBulkUpload = process.env.REACT_APP_API_URL_Bulk;
  const batch_conversion = BATCH_CONVERSION;
  const [isConvertToJson, setIsConvertToJson] = useState(false);
  const [isConvertTocsv, setIsConvertTocsv] = useState(false);
  const [isConvertToXml, setIsConvertToXml] = useState(false);
  const [isFileFormatError, setIsFileFormatError] = useState(false);
  const [fileFormatErrorMessage, setFileFormatErrorMessage] = useState("");
  const [failedFile, setFailedFile] = useState<errorFileds[]>([]);
  const [errorFor, setErrorFor] = useState(uploadErrorFor ?? "");
  const [hasError, setHasError] = useState(isError ?? false);

  useEffect(() => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        if (results) {
          const types = new Set<string>();
          results.forEach((item: any) => {
            if (item.FORMAT_NAME === selectedOption) {
              const supportedTypes: string[] = JSON.parse(
                item.SUPPORTED_OUTPUT_TYPES
              );
              supportedTypes.forEach((type) => {
                if (typeof type === "string") {
                  types.add(type);
                }
              });
            }
          });
          setSupportedOutputTypes(Array.from(types));
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("Error fetching data.Please try again later.", "error");
      });
  }, []);

  const handleBack = () => {
    navigate("/upload_file");
  };

  const handleConvert = (
    format: string,
    endpoint: string,
    setConversionState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (fileName?.length !== 0) {
      if (fileName?.length === 1) {
        axios
          .get(`${apiUrl + endpoint}`, {
            params: {
              filename: fileName[0],
              format_name: selectedOption,
              user_id: userID,
              orgid: orgID,
              conversion_type: fileName?.length === 1 ? "" : format,
            },
          })
          .then((response) => {
            console.log(response);
            setConversionState(true);
            // Data object to pass to the File_convertion page
            const data = {
              isConvertToJson: format === "json" ? true : false,
              isConvertTocsv: format === "csv" ? true : false,
              isConvertToXml: format === "xml" ? true : false,
              uploadedFileName: fileName ? fileName : [],
              format_name: selectedOption,
              setFileName: setFileName,
            };
            navigate("/upload_file/File_convertion", { state: data });
          })
          .catch((error) => {
            console.error("Error:", error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.detail
            ) {
              setFileFormatErrorMessage(error.response.data.detail);
              setIsFileFormatError(true);
              showToast(error.response.data.detail, "error");
            } else {
              showToast("Something went wrong", "error");
            }
            setConversionState(false);
          });
      } else {
        axios
          .post(`${apiUrlBulkUpload + batch_conversion}`, "", {
            params: {
              filenames: fileName?.join(","),
              org_id: parseInt(orgID ?? ""),
              user_id: parseInt(userID ?? ""),
              format_name: selectedOption,
              conversion_type: format,
            },
          })
          .then((response) => {
            let hasError = false;
            // console.log("Batch conversion response:", response?.data?.message);
            response?.data?.message.forEach((item: any) => {
              if (item.error) {
                // If there's an error, set the flag and store the failed file
                hasError = true;
                failedFile.push(item);
              }
            });
            // Set the conversion state based on the presence of errors
            if (hasError) {
              setConversionState(false);
              setFailedFile(failedFile);
              setHasError(true);
              setErrorFor("conversion");
            } else {
              setConversionState(true);
              // Data object to pass to the File_convertion page
              const data = {
                isConvertToJson: format === "json" ? true : false,
                isConvertTocsv: format === "csv" ? true : false,
                isConvertToXml: format === "xml" ? true : false,
                uploadedFileName: fileName ? fileName : [],
                format_name: selectedOption,
              };
              navigate("/upload_file/File_convertion", { state: data });
            }
          })
          .catch((error) => {
            console.error("Error during batch conversion:", error);
            // Check if error response contains a message and display it
            if (
              error.response &&
              error.response.data &&
              error.response.data.detail
            ) {
              const errorMsg = error.response.data.detail;
              setFileFormatErrorMessage(errorMsg);
              showToast(errorMsg, "error");
            } else {
              showToast(
                "Something went wrong during batch conversion",
                "error"
              );
            }
            setConversionState(false);
          });
      }
    } else {
      showToast("Filename is missing", "error");
    }
  };

  const handleConvertToJson = () => {
    setIsConvertToJson(true);
    handleConvert("json", invoice_to_json, setIsConvertToJson);
  };

  const handleConvertToCsv = () => {
    setIsConvertTocsv(true);
    handleConvert("csv", invoice_to_csv, setIsConvertTocsv);
  };

  const handleConvertToXml = () => {
    setIsConvertToXml(true);
    handleConvert("xml", invoice_to_xml, setIsConvertToXml);
  };

  const closeFileDetailsModal = () => {
    // setFileName([]);
    setFileFormatErrorMessage("");
  };

  return (
    <div className="container mx-auto px-6 py-6">
      <div className="flex justify-start">
        <button
          aria-label="Go Back"
          className="flex items-center px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300"
          onClick={handleBack}
        >
          <IconArrowBigLeftFilled
            stroke={1}
            color="#ffffff"
            className="h-6 w-6 mr-2"
          />
          <span className="text-lg">Back</span>
        </button>
      </div>

      <div
        className="max-h-96 overflow-x-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-200"
        style={{ WebkitOverflowScrolling: "touch", whiteSpace: "nowrap" }}
      >
        <div className="p-4">
          <h2 className="font-semibold text-2xl text-blue-700 mt-6 mb-6">
            Upload File List
          </h2>
          <div
            className={`p-2 grid gap-2 ${
              fileName?.length > 4 ? "grid-cols-2" : "grid-cols-1"
            }`}
          >
            {fileName?.length !== 0 ? (
              fileName?.map((file: string, index: number) => (
                <div
                  key={index}
                  className="relative flex items-center justify-between p-2 mb-4 border-2 border-gray-200 rounded-lg bg-blue-50 hover:bg-blue-100 transition-colors duration-300 group"
                >
                  {/* Display file name */}
                  <div className="flex items-center">
                    <img
                      src={pdf}
                      alt="pdf-icon"
                      className="h-10 w-10 rounded-md"
                    />
                    <div className="ml-4 flex flex-col">
                      <p className="text-gray-700 font-medium">{file}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>

      <h2 className="font-medium text-center text-xl mt-12">Convert To</h2>
      {supportedOutputTypes.length === 0 ? (
        <>
          <p className="text-center text-gray-500 mt-4">Loading...</p>
          <Spinner />
        </>
      ) : (
        <div className="flex flex-col items-center mt-6 space-y-4">
          <div className="flex space-x-6">
            {supportedOutputTypes.includes("json") && (
              <button
                type="button"
                onClick={handleConvertToJson}
                className="px-6 py-3 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center transition-all duration-200"
              >
                JSON
              </button>
            )}
            {supportedOutputTypes.includes("csv") && (
              <button
                type="button"
                onClick={handleConvertToCsv}
                className="px-6 py-3 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center transition-all duration-200"
              >
                CSV
              </button>
            )}
            {supportedOutputTypes.includes("xml") && (
              <button
                type="button"
                onClick={handleConvertToXml}
                className="px-6 py-3 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center transition-all duration-200"
              >
                XML
              </button>
            )}
          </div>
        </div>
      )}

      {fileFormatErrorMessage && (
        <WarningPopup
          isOpen={isFileFormatError}
          onClose={closeFileDetailsModal}
          title=" "
          showCancelButton={true}
          size="w-2/5"
        >
          <div className="p-3 text-center">
            <span className=" text-lg">{fileFormatErrorMessage}</span>
          </div>
        </WarningPopup>
      )}

      {hasError && (
        <FailedFilesWarning
          failedFile={errorFor === "conversion" ? failedFile : errorMessages} 
          msgFor={errorFor}
          setFailedFile={setFailedFile}
          setHasError={setHasError}
        />
      )}
    </div>
  );
};

export default UploadedFileDetails;
