import LoginPage from "../pages/login/LoginPage";
import DashboardPage from "../pages/dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Home from "../pages/home/Home";
import ResetPassword from "../pages/login/ResetPassword";
import ForgotPassword from "../pages/login/ForgotPassword";
import MonitorActions from "../pages/adminAction/monitoring/MonitorActions";
import Stats from "../pages/adminAction/stats/Stats";
import CreateFormat from "../pages/adminAction/createNewFormat/CreateFormat";
import UpdateFormat from "../pages/adminAction/editFormat/UpdateFormat";
import FormatDetails from "../pages/adminAction/formatDetails/FormatDetails";
import ConfigApiList from "../pages/adminAction/configApi/ConfigApiList";
import ConfigApi from "../pages/adminAction/configApi/configApi";
import EditConfigApi from "../pages/adminAction/configApi/EditConfigApi";
import History from "../pages/history/History";
import FileUpload from "../pages/dashboard/FileUpload";
import UploadedFileDetails from "../pages/dashboard/UploadedFileDetails";
import ViewJson from "../pages/supportedFormats/json/ViewJson";
import ConfirmationPopup from "../pages/dashboard/ConfirmationPopup";
import EditJson from "../pages/supportedFormats/json/EditJson";
import ViewCsv from "../pages/supportedFormats/csv/ViewCsv";
import EditCsv from "../pages/supportedFormats/csv/EditCsv";
import ViewXml from "../pages/supportedFormats/xml/ViewXml";
import EditXml from "../pages/supportedFormats/xml/EditXml";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetPassword" element={<ResetPassword />}/>
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/create-format" element={<CreateFormat />} />
        <Route path="/update-format" element={<UpdateFormat />} />
        <Route path="/monitoring" element={<MonitorActions />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/FormatDetails" element={<FormatDetails />} />
        <Route path="/addConfigApi" element={<ConfigApi />} />
        <Route path="/ConfigApi" element={<ConfigApiList />} />
        <Route path="/editConfigApi" element={<EditConfigApi />} />
        <Route path="/history" element={<History />} />
        <Route path="/upload_file" element={<FileUpload />} />
        <Route path="/upload_file/File_details"  element={<UploadedFileDetails />} />
        <Route path="/upload_file/File_convertion"  element={<ConfirmationPopup />} />
        <Route path="/upload_file/viewJson" element={<ViewJson />} />
        <Route path="/upload_file/editJson" element={<EditJson />} />
        <Route path="/upload_file/viewCsv" element={<ViewCsv />} />
        <Route path="/upload_file/editCsv" element={<EditCsv />} />
        <Route path="/upload_file/viewXml" element={<ViewXml />} />
        <Route path="/upload_file/editXml" element={<EditXml />} />
        {/* <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />} /> */}
      </Routes>
    </AuthProvider>
  );
};

export default AppRoutes;
