import { useEffect, useState } from "react";
import HistoryTable from "./HistoryTable";
import axios from "axios";
import { useToast } from "../../components/Toast";
import { INVOICE_VERSIONS } from "../../utils/api-details/ApiList";

const History = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [invoiceData, setInvoiceData] = useState([]);
  const { showToast } = useToast();
  const invoice_versions = INVOICE_VERSIONS;

  useEffect(() => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + invoice_versions}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response.data?.data?.data?.results;
        setInvoiceData(results);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("We encountered an issue while retrieving the data. Please try again later.", "error");
      });
  }, [apiUrl, orgID, userID]);

  return (
      <div>
        <HistoryTable invoiceData={invoiceData} />
      </div>
  
  );
};

export default History;
