import { useEffect, useState } from "react";
import { downloadJSON, downloadPDF } from "../../../components/Download";
import axios from "axios";
import parse from "html-react-parser";
import {
  IconArrowBigLeftFilled,
  IconTriangleInvertedFilled,
} from "@tabler/icons-react";
import CalculatorPage from "../../../components/Calculator";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useToast } from "../../../components/Toast";
import {
  HTML_TO_PDF,
  INVOICE_VERSION_BY_INVOICE_NAME,
  JSON_TO_HTML,
  VIEW_JSON,
} from "../../../utils/api-details/ApiList";
import { useLocation, useNavigate } from "react-router-dom";

const ViewJson = () => {
  const location = useLocation();
  const {
    isViewModalOpen,
    documentName,
    format_name,
    back,
    uploadedFileName,
    latestVersion,
    invoiceType,
    selectedFileName,
    editedData,
  } = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL;
  const [fileName, setFileName] = useState("");
  const [jsonData, setJsonData] = useState(editedData ?? "");
  const [isViewHtml, setIsViewHtml] = useState(false);
  const [htmlData, setHtmlData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [versionList, setVersionList] = useState([]);
  const { showToast } = useToast();
  const view_json = VIEW_JSON;
  const invoice_version_by_invoice_name = INVOICE_VERSION_BY_INVOICE_NAME;
  const json_to_html = JSON_TO_HTML;
  const html_to_pdf = HTML_TO_PDF;
  const navigate = useNavigate();

  useEffect(() => {
    previewFile(invoiceType ? invoiceType : "appended");
    getVersionList("");
    if (documentName) {
      const fileName = documentName?.split(".")[0];
      setFileName(fileName);
    }
  }, [apiUrl, fileName, isViewModalOpen, uploadedFileName, documentName]);

  useEffect(() => {
    if (selectedVersion) {
      previewFile(
        selectedOption === "original_json" || invoiceType === "original"
          ? "original"
          : "appended"
      );
      getVersionList(
        selectedOption === "original_json" ? "original_json" : "appended_json"
      );
    }
  }, [selectedVersion]);

  const previewFile = (type: string) => {
    if (fileName !== "") {
      axios
        .get(`${apiUrl + view_json}/${fileName + ".json"}`, {
          params: {
            format_name: format_name,
            version:
              selectedVersion || latestVersion
                ? selectedVersion || latestVersion
                : 0,
            type: type,
            orgid: orgID,
            user_id: userID,
          },
        })
        .then((response) => {
          setJsonData(response.data);
        })
        .catch(function (error) {
          console.log(error);
          const errorMsg = error?.response?.data?.detail;
          setJsonData(errorMsg);
          showToast(errorMsg ? errorMsg : "Something went wrong!", "error");
        });
    }
  };

  const getVersionList = async (optionSelected: string) => {
    const payload = {
      invoice_name: fileName + ".json",
      format_name: format_name,
      org_id: orgID,
      user_id: userID,
    };
    if (fileName !== "") {
      await axios
        .post(`${apiUrl + invoice_version_by_invoice_name}`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response?.data?.data?.results) {
            let allVersions = [];
            if (
              optionSelected === "original_json" ||
              invoiceType === "original"
            ) {
              const originalData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "ORIGINAL" ||
                  item.FINVOICE_TYPE === "original"
              );
              allVersions =
                originalData.length > 0
                  ? originalData[0].all_version.split(",")
                  : [];
            } else if (
              optionSelected === "appended_json" ||
              optionSelected === "" ||
              invoiceType === "appended"
            ) {
              const appendedData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "APPENDED" ||
                  item.FINVOICE_TYPE === "appended"
              );
              allVersions =
                appendedData.length > 0
                  ? appendedData[0].all_version.split(",")
                  : [];
            }
            setVersionList(allVersions);
          }
        })
        .catch(function (error) {
          console.log(error);
          showToast(
            "Something went wrong while getting invoice version!",
            "error"
          );
        });
    }
  };

  const handleEditJson = () => {
    navigate("/upload_file/editJson", {
      state: {
        fileName,
        selectedOption,
        format_name,
        orgID,
        userID,
        selectedVersion,
        uploadedFileName,
      },
    });
  };

  // const handleSave = (parsedData: any) => {
  //   setJsonData(parsedData);
  //   // setIsEditModalOpen(false);
  //   getVersionList(selectedOption ?? "");
  // };

  const handleDownloadJSON = async () => {
    await downloadJSON(
      apiUrl,
      fileName,
      selectedOption,
      format_name,
      selectedVersion,
      latestVersion,
      invoiceType
    )
      .then(() => {
        showToast("Json downloaded successfully", "success");
      })
      .catch((error) => {
        console.error("Error downloading Json:", error);
        showToast("Error downloading Json file!", "error");
      });
  };

  const handleDownloadPDF = () => {
    downloadPDF(apiUrl, fileName, format_name)
      .then(() => {
        showToast("PDF downloaded successfully", "success");
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
        const errorMsg = error?.response?.statusText
          ? "File " + error?.response?.statusText
          : "Something went wrong!";
        showToast(errorMsg, "error");
      });
  };

  const handleViewHtml = async () => {
    setIsViewHtml(true);
    const name = fileName?.split(".")[0] + ".json";
    await axios
      .get(`${apiUrl + json_to_html}/${name}`, {
        params: {
          format_name: format_name,
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        setHtmlData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setHtmlData(error?.response?.data?.detail);
        const errorMsg = error?.response?.data?.detail;
        showToast(errorMsg ? errorMsg : "Something went wrong!", "error");
      });

    await axios
      .get(`${apiUrl + html_to_pdf}/${name}`, {
        params: {
          format_name: format_name,
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        showToast("Something went wrong!", "error");
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    getVersionList(value);
    switch (value) {
      case "html_view":
        handleViewHtml();
        setIsViewHtml(true);
        break;
      case "original_json":
        previewFile("original");
        setIsViewHtml(false);
        break;
      case "appended_json":
        previewFile("appended");
        setIsViewHtml(false);
        break;
      default:
        setIsViewHtml(false);
        setSelectedOption("");
        break;
    }
  };

  const handleSelectedVersion = (selected: { value: string }) => {
    const value = selected.value;
    setSelectedVersion(value);
  };

  const handleCloseViewJson = () => {
    if (back === undefined) {
      navigate("/history");
    } else {
      navigate("/upload_file/File_convertion", {
        state: {
          uploadedFileName,
          isConvertToJson: true,
          format_name,
        },
      });
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center space-x-4">
        {/* Back Button at the start */}
        <div className="flex justify-start pl-2">
          <button
            aria-label="Go Back"
            className="flex items-center px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300"
            onClick={handleCloseViewJson}
          >
            <IconArrowBigLeftFilled
              stroke={1}
              color="#ffffff"
              className="h-6 w-6 mr-2"
            />
            <span className="text-lg">Back</span>
          </button>
        </div>
        {/* Dropdown and Action Buttons */}
        <div className="flex justify-end space-x-4 items-center">
          <div className="border border-gray-300 rounded-lg relative">
            <select
              className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              id="dropdown"
              value={selectedOption}
              onChange={handleChange}
            >
              <option value="">View Option</option>
              {(selectedOption === "appended_json" ||
                invoiceType === "appended" ||
                (selectedOption === "" && invoiceType === undefined)) && (
                <option value="original_json">Original JSON</option>
              )}
              {(isViewHtml ||
                selectedOption === "original_json" ||
                invoiceType === "original") && (
                <option value="appended_json">Appended Json</option>
              )}
              {!isViewHtml && <option value="html_view">HTML View</option>}
            </select>
            <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2 text-gray-700">
              <IconTriangleInvertedFilled className="w-4 h-2 text-gray-400 " />
            </div>
          </div>

          {!isViewHtml && (
            <Dropdown
              options={versionList}
              onChange={handleSelectedVersion}
              value={selectedVersion}
              placeholder="Select Version"
              controlClassName="w-full px-4 py-2 !border-1 !border-blue-700 !rounded-lg !text-blue-600 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              menuClassName="border-2 border-blue-500 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto"
              arrowClassName="mt-1"
            />
          )}

          {!isViewHtml && (
            <>
              <button
                type="button"
                className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                onClick={handleEditJson}
              >
                Edit
              </button>
              <button
                type="button"
                className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                onClick={handleDownloadJSON}
              >
                Download
              </button>
            </>
          )}

          {isViewHtml && (
            <button
              type="button"
              className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleDownloadPDF}
            >
              Download
            </button>
          )}

          <CalculatorPage />
        </div>
      </div>

      {!isViewHtml && (
        <div className="items-center justify-between mt-12 p-2">
          <div>
            <h2 className="font-bold text-2xl text-blue-700 mt-6 mb-6">
              {selectedOption === "original_json" || invoiceType === "original"
                ? "Original Json"
                : "Appended Json"}
            </h2>
            <pre className="p-4 rounded-lg whitespace-pre-wrap border-2 border-gray-300 bg-blue-50 shadow-lg min-h-96">
              {jsonData ? JSON.stringify(jsonData, null, 2) : "Loading..."}
            </pre>
          </div>
        </div>
      )}

      {isViewHtml && (
        <div className="items-center justify-between mt-10 p-4 mb-14">
          <h2 className="font-semibold text-2xl text-blue-700  mb-6  !border-b-0">
            HTML View
          </h2>
          {htmlData ? parse(htmlData) : "Loading..."}
        </div>
      )}
    </div>
  );
};

export default ViewJson;
