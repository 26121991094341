import React, { useState } from "react";
import { useToast } from "../../components/Toast";
import TableComponent from "../../components/TableComponent";
import { useNavigate } from "react-router-dom";

interface InvoiceData {
  invoiceName: string;
  CSV_Count: number;
  XML_Count: number;
  JSON_Count: number;
  PDF_Count: number;
  FINVOICE_NUMBER: string;
  FINVOICE_TYPE: string;
  FINVOICE_NAME: string;
  FORMAT_NAME: string;
  FINVOICE_ID: number;
}

interface InvoiceTableProps {
  invoiceData: InvoiceData[];
}

const HistoryTable: React.FC<InvoiceTableProps> = ({ invoiceData }) => {
  const [format_name, setFormatName] = useState("");
  const [latestVersion, setLatestVersion] = useState<number>();
  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleInvoiceClick = (
    invoiceName: string,
    formatName: string,
    count: number,
    invoiceType: string,
    type: string
  ) => {
    setFormatName(formatName);
    const documentName = invoiceName?.split("_mapped")[0];
    setLatestVersion(count - 1);
    if (count > 0) {
      if (type === "json") {
        navigate("/upload_file/viewJson", {
          state: {
            documentName,
            type,
            invoiceType,
            latestVersion,
            format_name: formatName,
          },
        });
      } else if (type === "csv") {
        navigate("/upload_file/viewCsv", {
          state: {
            documentName,
            type,
            invoiceType,
            latestVersion,
            format_name:formatName ,
          },
        });
      } else if (type === "xml") {
        navigate("/upload_file/viewXml", {
          state: {
            documentName,
            type,
            invoiceType,
            latestVersion,
            format_name: formatName,
          },
        });
      }
    } else {
      showToast("No version found", "error");
    }
  };

  // Sorting invoiceData
  const sortedInvoiceData = [...invoiceData].sort(
    (a, b) => b.FINVOICE_ID - a.FINVOICE_ID
  );

  const columns = [
    {
      name: "Invoice Number",
      selector: (row: InvoiceData) => row.FINVOICE_NUMBER,
      sortable: true,
      filterable: true,
    },
    {
      name: "Invoice Name",
      selector: (row: InvoiceData) => row.FINVOICE_NAME,
      sortable: true,
      filterable: true,
    },
    {
      name: "Invoice Type",
      selector: (row: InvoiceData) => row.FINVOICE_TYPE,
      sortable: true,
    },
    {
      name: "CSV",
      selector: (row: InvoiceData) => row.CSV_Count,
      sortable: true,
      cell: (row: InvoiceData) => (
        <span
          className="cursor-pointer text-blue-600 hover:underline"
          onClick={() =>
            handleInvoiceClick(
              row.FINVOICE_NAME,
              row.FORMAT_NAME,
              row.CSV_Count,
              row.FINVOICE_TYPE,
              "csv"
            )
          }
        >
          {row.CSV_Count}
        </span>
      ),
    },
    {
      name: "XML",
      selector: (row: InvoiceData) => row.XML_Count,
      sortable: false,
      cell: (row: InvoiceData) => (
        <span
          className="cursor-pointer text-blue-600 hover:underline"
          onClick={() =>
            handleInvoiceClick(
              row.FINVOICE_NAME,
              row.FORMAT_NAME,
              row.XML_Count,
              row.FINVOICE_TYPE,
              "xml"
            )
          }
        >
          {row.XML_Count}
        </span>
      ),
    },
    {
      name: "JSON",
      selector: (row: InvoiceData) => row.JSON_Count,
      sortable: false,
      cell: (row: InvoiceData) => (
        <span
          className="cursor-pointer text-blue-600 hover:underline"
          onClick={() =>
            handleInvoiceClick(
              row.FINVOICE_NAME,
              row.FORMAT_NAME,
              row.JSON_Count,
              row.FINVOICE_TYPE,
              "json"
            )
          }
        >
          {row.JSON_Count}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="overflow-x-auto">
        {sortedInvoiceData.length !== 0 ? (
          <TableComponent
            title={"History"}
            columns={columns}
            rows={sortedInvoiceData}
          />
        ) : (
          <div className="p-4 border-2 border-gray-300 rounded-lg min-h-96">
            <h2 className="text-blue-700 font-bold text-lg">History</h2>
            <p className="text-center text-gray-600">No Data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryTable;
