import { useState, useRef, useEffect } from "react";
import Modal from "../../components/Modal";

const NeedNewFormat = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (email && message) {
      if (onClose) onClose();
    } else {
      console.log("Please fill in both email and message");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    // Basic email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    setIsEmailValid(emailPattern.test(value));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title=" " size="w-4/12">
      <p className="text-gray-600 mb-6 text-center text-lg font-medium">
        Kindly provide your details, and we will reach out to you soon.
      </p>

      <form className="p-6 space-y-6" onSubmit={handleSubmit}>
        {/* Email Input */}
        <div>
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* Message Input */}
        <div>
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="block w-full px-4 py-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm transition-all"
            id="message"
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-3">
          <button
            type="submit"
            className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:bg-blue-400 disabled:cursor-not-allowed transition-all"
            disabled={!email || !message || !isEmailValid}
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NeedNewFormat;
