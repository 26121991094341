import EditStepper from "./EditStepper";

const UpdateFormat = () => {
  return (
    <div className="p-4">
      <EditStepper />
    </div>
  );
};

export default UpdateFormat;
