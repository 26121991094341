import { useState, useRef, useCallback, useEffect } from "react";
import profilePic from "./../../assets/profilePic.jpg";
import { IconLogout } from "@tabler/icons-react";

interface ProfileDropdownProps {
  handleLogout: () => void;
}

const ProfileDropdown = ({ handleLogout }: ProfileDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const profilePicRef = useRef<HTMLImageElement | null>(null);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prevState) => !prevState);
  }, []);

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        profilePicRef.current &&
        !profilePicRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-end relative">
      <img
        src={profilePic}
        alt="Profile"
        className="w-12 h-12 rounded-full cursor-pointer"
        onClick={toggleDropdown}
        aria-label="User profile"
        ref={profilePicRef}
      />

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-14 w-48 bg-blue-700 border rounded-md shadow-lg z-10"
        >
          <button
            className="w-full text-left px-6 py-2 flex items-center space-x-2 hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            onClick={handleLogout}
          >
            <IconLogout stroke={2} className="w-5 h-5 text-white" />
            <span className="text-lg font-medium text-white">Logout</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
