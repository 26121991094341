import { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import axios from "axios";
import { IconCheck } from "@tabler/icons-react";
import FormatName from "../steps/FormatName";
import AddFields from "../steps/addFields/AddFields";
import Mapping from "../steps/mapping/Mapping";
import InvoiceList from "../steps/formatList/InvoiceList";
import { useNavigate } from "react-router-dom";
import { useToast } from "./../../../components/Toast";
import ConfirmationLookupConfig from "../steps/configureLookup/ConfirmLookUpConfig";
import ConfigureLookup from "../steps/configureLookup/ConfigureLookup";
import ConfirmConfigure from "../steps/finalConfirmation/ConfirmConfigure";
import {
  ADD_FIELDS,
  GET_FORMAT_NAME,
  GET_OUTPUT_TYPES,
  UPDATE_MAPPING_FIELDS,
  VIEW_FIELDS,
  VIEW_FORMAT_HEADERS,
  VIEW_MAPPING_FIELDS,
} from "../../../utils/api-details/ApiList";
import ErrorPopup from "../../../components/ErrorPopup";
import Papa from "papaparse";

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}

interface CSVData {
  headers: string[];
  data: string[][];
}

interface LookupDetails {
  url?: string;
  method?: string;
  username?: string;
  password?: string;
  requestBody?: {};
  pathParam?: {};
}

// Define steps for the stepper
const steps = [
  "Format List",
  "Format Name",
  "Add Fields",
  "Mapping Field",
  "Configure Lookup",
  "Confirm Configure",
];

// Map steps to components
const stepComponents = [
  InvoiceList,
  FormatName,
  AddFields,
  Mapping,
  ConfigureLookup,
  ConfirmConfigure,
];

const EditStepper = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentStep, setCurrentStep] = useState(0);
  const [formatName, setFormatName] = useState("");
  const [supportedOutput, setSupportedOutput] = useState<string[]>([]);
  const [isToggledForHeader, setIsToggledForHeader] = useState(false);
  const [fieldsList, setFieldsList] = useState<string[]>([]);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [jsonFields, setJsonFields] = useState<string[]>([]);
  const [xmlFields, setXmlFields] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const [jsonFiles, setJsonFiles] = useState<File[]>([]);
  const [xmlFiles, setXmlFiles] = useState<File[]>([]);
  const [mapping, setMapping] = useState<{ [key: string]: string }>({});
  const [csvMapping, setCsvMapping] = useState<{ [key: string]: string }>({});
  const [jsonMapping, setJsonMapping] = useState<{ [key: string]: string }>({});
  const [xmlMapping, setXmlMapping] = useState<{ [key: string]: string }>({});
  const [outputFields, setOutputFields] = useState<any[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [showNull, setShowNull] = useState("");
  const [dictionaryMappingRequired, setDictionaryMappingRequired] =
    useState("");
  const [value, setValue] = useState<string | null>(null);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [outputCsvFields, setOutputCsvFields] = useState<any[]>([]);
  const [outputJsonFields, setOutputJsonFields] = useState<any[]>([]);
  const [outputXmlFields, setOutputXmlFields] = useState<any[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<any[]>([]);
  const [fileExtractedData, setFileExtractedData] = useState({});
  const [buyerSupplier, setBuyerSupplier] = useState<BuyerSupplierData[]>([]);
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const [isConfigureLookup, setIsConfigureLookup] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [activeTab, setActiveTab] = useState("buyer");
  const [dictionaryObject, setDictionaryObject] = useState("");
  const get_format_name = GET_FORMAT_NAME;
  const get_output_types = GET_OUTPUT_TYPES;
  const add_fields = ADD_FIELDS;
  const view_fields = VIEW_FIELDS;
  const update_mapping_fields = UPDATE_MAPPING_FIELDS;
  const view_mapping_fields = VIEW_MAPPING_FIELDS;
  const view_format_headers = VIEW_FORMAT_HEADERS;
  const [isEdit, setIsEdit] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const [isErrorInStep, setIsErrorInStep] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buyerDictionaryObject, setBuyerDictionaryObject] = useState("");
  const [buyerLookupDetails, setBuyerLookupDetails] = useState<LookupDetails>(
    {}
  );
  const [supplierDictionaryObject, setSupplierDictionaryObject] = useState("");
  const [supplierLookupDetails, setSupplierLookupDetails] =
    useState<LookupDetails>({});
  const mandatoryFields = [
    "Invoice_No",
    "Invoice_Date",
    "Due_Date",
    "Total_Amount",
    "Total_Gst",
    "Payable_To",
    "Invoice_Currency",
    "Buyer_Name",
    "Commodity_Data",
    "Taxes_Table",
    "Charges_Table",
  ];
  useEffect(() => {
    setIsEdit(true);
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        if (results) {
          setSelectedFormat([]);
          const sortedResults = results
            ?.filter((item: any) => item.status !== "IN ACTIVE")
            .sort(
              (a: any, b: any) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID
            );

          const formatNames = sortedResults?.map(
            (item: { FORMAT_NAME: string }) => item.FORMAT_NAME
          );
          setSelectedFormat(formatNames);
          // Get the formatId based on the selected format name
          const formatId = results
            ?.filter(
              (item: { FORMAT_NAME: string }) => item.FORMAT_NAME === formatName
            )
            .map(
              (item: { INVOICE_FORMAT_ID: number }) => item.INVOICE_FORMAT_ID
            );
          const id = formatId[0];
          // setSelectedFormatId(id);
          if (id) {
            getOutputTypes(id);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("Something went wrong!", "error");
      });
  }, [apiUrl, formatName, orgID, userID]);

  // Fetch supported types based on the formatId
  const getOutputTypes = (formatId: number) => {
    const payload = {
      format_id: formatId,
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_output_types}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const result: [] = response?.data?.data?.data?.results;
        if (result.length <= 1) {
          const type =
            response?.data?.data?.data?.results[0].SUPPORTED_OUTPUT_TYPES;
          const supportedType = JSON.parse(type);
          setIsToggledForHeader(true);
          setSupportedOutput([]);
          setSupportedOutput(supportedType);
        } else {
          setIsToggledForHeader(false);
          const allSupportedTypes = result
            .map((item: { SUPPORTED_OUTPUT_TYPES: any }) =>
              JSON.parse(item.SUPPORTED_OUTPUT_TYPES)
            )
            .flat();
          setSupportedOutput([]);
          setSupportedOutput(allSupportedTypes);
        }
        viewFieldsAPI();
        viewMappingFieldsAPI();
      })
      .catch((error) => {
        console.error("Error fetching output types:", error);
      });
  };

  useEffect(() => {
    viewFieldsAPI();
    viewMappingFieldsAPI();
  }, [isToggledForHeader, supportedOutput]);

  const handleNext = async () => {
    const apiSteps = [2, 3]; // Steps requiring API calls
    if (apiSteps.includes(currentStep)) {
      await apiCallForStep(currentStep);
    }
    if (currentStep + 1 === 4) {
      setIsLookupModalOpen(true);
    }
    setCurrentStep(currentStep + 1);
    // setIsError(false);
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
    if (!isConfigureLookup) {
      if (currentStep - 1 === 4) {
        setCurrentStep(3);
      }
    }
  };

  const handleOk = () => {
    navigate("/dashboard");
    setIsEdit(false);
  };

  const CurrentStepComponent = stepComponents[currentStep];

  function normalizeField(field: string) {
    // Handle Camel Case to Pascal Case with Underscore
    if (/[a-z][A-Z]/.test(field)) {
      return field
        .replace(/([a-z])([A-Z])/g, "$1_$2")
        .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
        .replace(/^([a-z])/g, (match) => match.toUpperCase());
    }

    // Handle Uppercase with Underscore to Pascal Case with Underscore
    if (field === field.toUpperCase() && /_/.test(field)) {
      return field
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join("_");
    }

    // Handle Snake Case and Lowercase with Underscore to Pascal Case with Underscore
    if (/_/.test(field)) {
      return field
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize the first letter of each word
        .join("_");
    }
    return field.charAt(0).toUpperCase() + field.slice(1);
  }

  const addFieldsAPI = async () => {
    const formatType = supportedOutput.join(",");
    const fields = fieldsList.join(",");
    const formData = new FormData();
    // Normalize both fieldsList and mandatoryFields
    const normalizedFieldsList = fieldsList.map(normalizeField);
    const normalizedMandatoryFields = mandatoryFields.map(normalizeField);
    // Step 1: Validate that mandatory fields are included in fieldsList
    if (fieldsList.length !== 0) {
      const missingMandatoryFields = normalizedMandatoryFields.filter(
        (field) => !normalizedFieldsList.includes(field)
      );
      if (missingMandatoryFields.length > 0) {
        console.error("Missing mandatory fields:", missingMandatoryFields);
        setErrorMessage(
          `Please include the following mandatory fields:\n${missingMandatoryFields.join(
            ", "
          )}`
        );
        setIsErrorModalOpen(true);
        return;
      }
    }
    // Step 2: Directly validate if CSV files contain mandatory fields in the headers
    for (const file of files) {
      try {
        const csvData = await parseCSVFile(file);
        // Normalize CSV headers for comparison
        const normalizedCsvHeaders = csvData.headers.map(normalizeField);
        // Check if mandatory fields are present in the CSV headers
        const missingFieldsInCSV = normalizedMandatoryFields.filter(
          (field) => !normalizedCsvHeaders.includes(field)
        );
        if (missingFieldsInCSV.length > 0) {
          console.error("CSV missing mandatory fields:", missingFieldsInCSV);
          setErrorMessage(
            `The CSV file is missing the following mandatory fields: ${missingFieldsInCSV.join(
              ", "
            )}`
          );
          setIsErrorModalOpen(true);
          return;
        }
      } catch (error) {
        console.error("Error parsing CSV file:", error);
        setErrorMessage("There was an error parsing the CSV file.");
        setIsErrorModalOpen(true);
        return;
      }
    }
    // Append valid files to FormData and other steps
    for (const file of files) {
      formData.append("file", file);
    }
    formData.append("fields", fieldsList.length > 0 ? fields : "");
    formData.append("format_type", formatType);
    formData.append("format_name", formatName);
    formData.append("orgid", orgID ?? "");
    formData.append("user_id", userID ?? "");
    if (isToggledForHeader) {
      try {
        const response = await axios.post(`${apiUrl + add_fields}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response from addFieldsAPI:", response);
        viewFieldsAPI();
      } catch (error) {
        console.error("Error in addFieldsAPI:", error);
      }
    } else {
      // Iterate over supported output formats
      for (const type of supportedOutput) {
        const formatData = new FormData();
        switch (type) {
          case "json":
            if (jsonFields.length !== 0) {
              const normalizedJsonFieldsList = jsonFields?.map(normalizeField);
              // Check for missing mandatory fields in jsonFields
              const missingJsonFields = normalizedMandatoryFields?.filter(
                (field) => !normalizedJsonFieldsList?.includes(field)
              );
              if (missingJsonFields.length > 0) {
                setErrorMessage(
                  `The JSON field are missing mandatory fields: ${missingJsonFields.join(
                    ", "
                  )}`
                );
                setIsErrorModalOpen(true);
                return;
              }
            }

            // Iterate over each JSON file and parse it
            for (const file of jsonFiles) {
              try {
                const jsonData = await parseCSVFile(file);
                const jsonHeaders = jsonData.headers;
                // Check if the JSON data has the necessary fields
                const missingFieldsInJson = normalizedMandatoryFields.filter(
                  (normalizedField) => {
                    return (
                      !jsonHeaders.includes(normalizedField) &&
                      !jsonHeaders.includes(normalizedField.toLowerCase()) &&
                      !jsonHeaders.includes(normalizedField.replace(/_/g, ""))
                    );
                  }
                );
                // If any mandatory fields are missing, show an error
                if (missingFieldsInJson.length > 0) {
                  setErrorMessage(
                    `The JSON files are missing mandatory fields: ${missingFieldsInJson.join(
                      ", "
                    )}`
                  );
                  setIsErrorModalOpen(true);
                  return;
                }
              } catch (error) {
                setErrorMessage("There was an error parsing the JSON file.");
                setIsErrorModalOpen(true);
                return;
              }
              // Append the JSON file to FormData after validation
              formatData.append("file", file);
            }
            const jsonFieldsString =
              jsonFields.length > 0 ? jsonFields.join(",") : "";
            formatData.append("fields", jsonFieldsString);
            break;

          case "csv":
            if (csvFields.length !== 0) {
              const normalizedCsvFieldsList = csvFields?.map(normalizeField);
              // Check for missing mandatory fields in csvFields
              const missingCsvFields = normalizedMandatoryFields?.filter(
                (field) => !normalizedCsvFieldsList?.includes(field)
              );
              if (missingCsvFields.length > 0) {
                setErrorMessage(
                  `The CSV fields are missing mandatory fields: ${missingCsvFields.join(
                    ", "
                  )}`
                );
                setIsErrorModalOpen(true);
                return;
              }
            }

            // Iterate over each CSV file and parse it
            for (const file of csvFiles) {
              try {
                const csvData = await parseCSVFile(file);
                console.log("csvData", csvData);
                const csvHeaders = csvData.headers;
                // Check if the mandatory fields are present in the CSV headers
                const missingFieldsInCSV = normalizedMandatoryFields.filter(
                  (normalizedField) => {
                    return (
                      !csvHeaders.includes(normalizedField) &&
                      !csvHeaders.includes(normalizedField.toLowerCase()) &&
                      !csvHeaders.includes(normalizedField.replace(/_/g, ""))
                    );
                  }
                );
                // If any mandatory fields are missing, show an error
                if (missingFieldsInCSV.length > 0) {
                  setErrorMessage(
                    `The CSV file is missing the following mandatory fields: ${missingFieldsInCSV.join(
                      ", "
                    )}`
                  );
                  setIsErrorModalOpen(true);
                  return;
                }
              } catch (error) {
                setErrorMessage("There was an error parsing the CSV file.");
                setIsErrorModalOpen(true);
                return;
              }
              // Append the CSV file to FormData after validation
              formatData.append("file", file);
            }
            const csvFieldsString =
              csvFields.length > 0 ? csvFields.join(",") : "";
            formatData.append("fields", csvFieldsString);
            break;

          case "xml":
            if (xmlFields.length !== 0) {
              const normalizedXmlFieldsList = xmlFields?.map(normalizeField);
              // Check for missing mandatory fields in xmlFields
              const missingXmlFields = normalizedMandatoryFields.filter(
                (field) => !normalizedXmlFieldsList?.includes(field)
              );
              if (missingXmlFields.length > 0) {
                setErrorMessage(
                  `The XML fields are missing mandatory fields: ${missingXmlFields.join(
                    ", "
                  )}`
                );
                setIsErrorModalOpen(true);
                return;
              }
            }
            // Iterate over each XML file and parse it
            for (const file of xmlFiles) {
              try {
                const xmlData = await parseCSVFile(file);
                console.log("xmlData", xmlData);
                const xmlHeaders = xmlData.headers || [];
                // Check if the mandatory fields are present in the XML headers/tags
                const missingFieldsInXML = normalizedMandatoryFields.filter(
                  (normalizedField) => {
                    return (
                      !xmlHeaders.includes(normalizedField) &&
                      !xmlHeaders.includes(normalizedField.toLowerCase()) &&
                      !xmlHeaders.includes(normalizedField.replace(/_/g, ""))
                    );
                  }
                );
                // If any mandatory fields are missing, show an error
                if (missingFieldsInXML.length > 0) {
                  setErrorMessage(
                    `The XML files are missing mandatory fields: ${missingFieldsInXML.join(
                      ", "
                    )}`
                  );
                  setIsErrorModalOpen(true);
                  return;
                }
              } catch (error) {
                setErrorMessage("There was an error parsing the XML file.");
                setIsErrorModalOpen(true);
                return;
              }
              // Append the XML file to FormData after validation
              formatData.append("file", file);
            }
            const xmlFieldsString =
              xmlFields.length > 0 ? xmlFields.join(",") : "";
            formatData.append("fields", xmlFieldsString);
            break;
          default:
            break;
        }
        formatData.append("format_type", type);
        formatData.append("format_name", formatName);
        formatData.append("orgid", orgID ?? "");
        formatData.append("user_id", userID ?? "");
        // Step 6: Send the FormData for each format via API
        try {
          const response = await axios.post(
            `${apiUrl + add_fields}`,
            formatData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(`Response for ${type} format:`, response);
          viewFieldsAPI();
        } catch (error) {
          console.error(`Error in addFieldsAPI for ${type}:`, error);
        }
      }
    }
  };

  const parseCSVFile = (file: File): Promise<CSVData> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (result) => {
          const headers = result.data[0] as string[];
          resolve({ headers, data: result.data as string[][] });
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const viewFieldsAPI = async () => {
    setOutputCsvFields([]);
    setCsvFields([]);
    setOutputJsonFields([]);
    setJsonFields([]);
    setOutputXmlFields([]);
    setXmlFields([]);
    setFieldsList([]);
    setOutputFields([]);
    try {
      // Fetch data for all formatTypes
      for (const formatType of supportedOutput) {
        const response = await axios.get(`${apiUrl + view_fields}`, {
          params: {
            format_type: formatType,
            format_name: formatName,
            orgid: orgID,
            user_id: userID,
          },
        });
        const fields = response.data.fields;
        if (isToggledForHeader) {
          setFieldsList(fields);
          setOutputFields(fields);
        }
        // Set specific fields based on formatType
        switch (formatType) {
          case "csv":
            setOutputCsvFields(fields);
            setCsvFields(fields);
            break;
          case "json":
            setOutputJsonFields(fields);
            setJsonFields(fields);
            break;
          case "xml":
            setOutputXmlFields(fields);
            setXmlFields(fields);
            break;
          default:
            console.warn(`Unknown formatType: ${formatType}`);
        }
      }
    } catch (error) {
      console.error("Error in viewFieldsAPI:", error);
    }
  };

  useEffect(() => {
    console.log("Updated CSV Fields:", outputCsvFields);
  }, [outputCsvFields]);

  useEffect(() => {
    console.log("Updated JSON Fields:", outputJsonFields);
  }, [outputJsonFields]);

  useEffect(() => {
    console.log("Updated XML Fields:", outputXmlFields);
  }, [outputXmlFields]);

  const mappingFieldsAPI = async () => {
    if (isToggledForHeader) {
      const updatedMapping = { ...mapping };
      outputFields.forEach((field) => {
        if (!(field in updatedMapping)) {
          updatedMapping[field] = "";
        }
      });
      // const formatType = supportedOutput.join(",");
      const payload = {
        data_dict: updatedMapping,
        data_setting_dict: {
          skip_null: showNull === "Yes" ? "True" : "False",
          alias: showNull === "Yes" ? value : "-",
          dictionary_mapping_required: dictionaryMappingRequired,
        },
      };
      for (const formatType of supportedOutput) {
        try {
          const response = await axios.patch(
            `${apiUrl + update_mapping_fields}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                format_type: formatType,
                format_name: formatName,
                orgid: orgID,
                user_id: userID,
              },
            }
          );
          console.log(`Response for ${formatType}:`, response.data);
        } catch (error) {
          console.error(`Error for ${formatType}:`, error);
        }
      }
    } else {
      const updatedCsvMapping = { ...csvMapping };
      outputCsvFields.forEach((field) => {
        if (!(field in updatedCsvMapping)) {
          updatedCsvMapping[field] = "";
        }
      });
      const updatedJsonMapping = { ...jsonMapping };
      outputJsonFields.forEach((field) => {
        if (!(field in updatedJsonMapping)) {
          updatedJsonMapping[field] = "";
        }
      });
      const updatedXmlMapping = { ...xmlMapping };
      outputXmlFields.forEach((field) => {
        if (!(field in updatedXmlMapping)) {
          updatedXmlMapping[field] = "";
        }
      });

      for (const formatType of supportedOutput) {
        let payload;
        switch (formatType.toLowerCase()) {
          case "csv":
            payload = {
              data_dict: updatedCsvMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          case "json":
            payload = {
              data_dict: updatedJsonMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          case "xml":
            payload = {
              data_dict: updatedXmlMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          default:
            break;
        }
        try {
          const response = await axios.patch(
            `${apiUrl + update_mapping_fields}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                format_type: formatType,
                format_name: formatName,
                orgid: orgID,
                user_id: userID,
              },
            }
          );
          console.log(
            `Response from mappingFieldsAPI for ${formatType}:`,
            response
          );
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
        }
      }
    }
  };

  const viewMappingFieldsAPI = async () => {
    if (formatName !== "") {
      getFormatHeaders();
    }
    if (isToggledForHeader) {
      const formatType = supportedOutput[0];
      axios
        .get(`${apiUrl + view_mapping_fields}`, {
          params: {
            format_type: formatType,
            format_name: formatName,
            user_id: parseInt(userID ?? ""),
            org_id: parseInt(orgID ?? ""),
          },
        })
        .then((response) => {
          console.log("Response from mappingFieldsAPI:", response?.data);
          const mappingValue = response?.data?.data_dict;
          setMapping(mappingValue);
          const skip_null = response?.data?.data_setting_dict?.skip_null;
          setValue(response?.data?.data_setting_dict?.alias);
          setShowNull(skip_null === "True" ? "Yes" : "No");
          setDictionaryMappingRequired(
            response?.data?.data_setting_dict?.dictionary_mapping_required
          );
        })
        .catch((error) => {
          console.error("Error in mappingFieldsAPI:", error);
          const errorMsg = error?.response?.data?.detail;
          showToast(errorMsg ? errorMsg : "Something went wrong", "error");
        });
    } else {
      for (const formatType of supportedOutput) {
        try {
          const response = await axios.get(`${apiUrl + view_mapping_fields}`, {
            params: {
              format_type: formatType,
              format_name: formatName,
              org_id: parseInt(orgID ?? ""),
              user_id: parseInt(userID ?? ""),
            },
          });
          console.log(
            `Response from mappingFieldsAPI for ${formatType}:`,
            response?.data
          );
          const mappingValue = response?.data?.data_dict;
          if (formatType === "csv") {
            setCsvMapping(mappingValue);
          }
          if (formatType === "json") {
            setJsonMapping(mappingValue);
          }
          if (formatType === "xml") {
            setXmlMapping(mappingValue);
          }
          const skip_null = response?.data?.data_setting_dict?.skip_null;
          setValue(response?.data?.data_setting_dict?.alias);
          setShowNull(skip_null === "True" ? "Yes" : "No");
          setDictionaryMappingRequired(
            response?.data?.data_setting_dict?.dictionary_mapping_required
          );
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
        }
      }
    }
  };

  const getFormatHeaders = async () => {
    axios
      .get(`${apiUrl + view_format_headers}`, {
        params: {
          org_id: orgID,
          user_id: userID,
          format_name: formatName,
        },
      })
      .then((response) => {
        const headers = response?.data?.headers?.headers || [];
        setFileExtractedData(headers);
      })
      .catch((error) => {
        console.error("Error fetching output types:", error);
        showToast(error?.response?.data?.detail, "error");
      });
  };

  // Call API for the respective step
  const apiCallForStep = async (stepIndex: number) => {
    switch (stepIndex) {
      case 2:
        return addFieldsAPI();
      case 3:
        return mappingFieldsAPI();
      default:
        throw new Error("No API defined for this step.");
    }
  };

  const handleCloseLookup = () => {
    setIsLookupModalOpen(false);
  };

  const handlePopupRequriedFields = () => {
    setIsErrorModalOpen(false);
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className=" border-2 border-gray-300 rounded-lg ">
      <div className="w-full flex flex-col items-center pb-10 ">
        <div className="w-full px-12">
          <div className="p-0 ">
            <Stepper activeStep={currentStep}>
              {steps.map((step, index) => {
                const isActive = index === currentStep;
                const isCompleted = index < currentStep;
                return (
                  <Step key={index} label={step}>
                    <div
                      className={`
                    ${isCompleted ? "bg-blue-500 text-white" : ""}${
                        isActive
                          ? "bg-blue-200  border-2 border-blue-500 text-black"
                          : ""
                      }${
                        !isActive && !isCompleted
                          ? "bg-gray-300 text-gray-600"
                          : ""
                      }
                    w-8 h-8 rounded-full flex justify-center items-center
                    text-sm font-semibold
                  `}
                    >
                      {isCompleted ? (
                        <IconCheck
                          stroke={2}
                          color="white"
                          className="text-xl"
                        />
                      ) : (
                        <span className="text-base">{index + 1}</span>
                      )}
                    </div>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div
            className={`min-h-96 max-h-[800vh]  p-4 border-2 border-gray-300 rounded-lg bg-sky-50`}
          >
            <CurrentStepComponent
              setFormatName={setFormatName}
              setSupportedOutput={setSupportedOutput}
              formatName={formatName}
              supportedOutput={supportedOutput}
              setIsToggledForHeader={setIsToggledForHeader}
              isToggledForHeader={isToggledForHeader}
              fieldsList={fieldsList}
              setFieldsList={setFieldsList}
              files={files}
              setFiles={setFiles}
              setMapping={setMapping}
              mapping={mapping}
              outputFields={outputFields}
              setHasChanges={setHasChanges}
              showNull={showNull}
              setShowNull={setShowNull}
              value={value}
              setValue={setValue}
              setCsvFiles={setCsvFiles}
              csvFiles={csvFiles}
              setJsonFiles={setJsonFiles}
              jsonFiles={jsonFiles}
              setXmlFiles={setXmlFiles}
              xmlFiles={xmlFiles}
              csvFields={csvFields}
              setCsvFields={setCsvFields}
              jsonFields={jsonFields}
              setJsonFields={setJsonFields}
              xmlFields={xmlFields}
              setXmlFields={setXmlFields}
              outputCsvFields={outputCsvFields}
              outputJsonFields={outputJsonFields}
              outputXmlFields={outputXmlFields}
              csvMapping={csvMapping}
              setCsvMapping={setCsvMapping}
              jsonMapping={jsonMapping}
              setJsonMapping={setJsonMapping}
              xmlMapping={xmlMapping}
              setXmlMapping={setXmlMapping}
              selectedFormat={selectedFormat}
              fileExtractedData={fileExtractedData}
              buyerSupplier={buyerSupplier}
              isCreate={false}
              isConfigureLookup={isConfigureLookup}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setDictionaryObject={setDictionaryObject}
              dictionaryObject={dictionaryObject}
              isEdit={isEdit}
              setIsErrorInStep={setIsErrorInStep}
              buyerDictionaryObject={buyerDictionaryObject}
              setBuyerDictionaryObject={setBuyerDictionaryObject}
              buyerLookupDetails={buyerLookupDetails}
              setBuyerLookupDetails={setBuyerLookupDetails}
              supplierDictionaryObject={supplierDictionaryObject}
              setSupplierDictionaryObject={setSupplierDictionaryObject}
              supplierLookupDetails={supplierLookupDetails}
              setSupplierLookupDetails={setSupplierLookupDetails}
            />
          </div>
        </div>
        <div className="flex space-x-4 mt-6">
          <button
            onClick={handlePrev}
            disabled={currentStep === 0}
            className={`px-4 py-2 rounded ${
              currentStep === 0
                ? "bg-gray-300 text-gray-500"
                : "bg-blue-600 text-white"
            }`}
          >
            Previous
          </button>
          <button
            onClick={currentStep === steps.length - 1 ? handleOk : handleNext}
            disabled={currentStep === steps.length - 1 && !formatName}
            className={`px-4 py-2 rounded ${
              currentStep === steps.length - 1
                ? "bg-blue-600 text-white"
                : "bg-blue-600 text-white"
            }`}
          >
            {currentStep === steps.length - 1 ? "OK" : "Save & Continue"}
          </button>
        </div>

        {isLookupModalOpen && (
          <ConfirmationLookupConfig
            isOpen={isLookupModalOpen}
            onClose={handleCloseLookup}
            setIsConfigureLookup={setIsConfigureLookup}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            isEdit={isEdit}
          />
        )}

        {isErrorModalOpen && (
          <ErrorPopup
            open={isErrorModalOpen}
            onClose={handlePopupRequriedFields}
            errorMessage={errorMessage}
            errorTitle="Mandatory Fields"
          />
        )}
      </div>
    </div>
  );
};

export default EditStepper;
