import { useEffect, useState } from "react";
import axios from "axios";
import CsvDataDisplay from "../../../components/CsvDataDisplay";
import {
  IconArrowBigLeftFilled,
  IconTriangleInvertedFilled,
} from "@tabler/icons-react";
import CalculatorPage from "../../../components/Calculator";
import { useCsvFileDownloader } from "../../../components/Download";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useToast } from "../../../components/Toast";
import {
  INVOICE_VERSION_BY_INVOICE_NAME,
  VIEW_CSV,
} from "../../../utils/api-details/ApiList";
import { useLocation, useNavigate } from "react-router-dom";

export const ViewCsv = () => {
  const location = useLocation();
  const {
    // isViewModalOpen,
    documentName,
    format_name,
    back,
    uploadedFileName,
    latestVersion,
    invoiceType,
    // selectedFileName,
    // editedData,
  } = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL;
  const [fileName, setFileName] = useState("");
  const [csvData, setCSVData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const downloadFile = useCsvFileDownloader(apiUrl, format_name);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [versionList, setVersionList] = useState<string[]>([]);
  const [error, setError] = useState("");
  const { showToast } = useToast();
  const view_csv = VIEW_CSV;
  const invoice_version_by_invoice_name = INVOICE_VERSION_BY_INVOICE_NAME;
  const navigate = useNavigate();

  useEffect(() => {
    previewFile(invoiceType ? invoiceType : "appended");
    getVersionList("");
    if (documentName) {
      const fileName = documentName?.split(".")[0];
      setFileName(fileName);
    }
  }, [apiUrl, fileName, documentName]);

  useEffect(() => {
    if (selectedVersion) {
      previewFile(
        selectedOption === "original_csv" || invoiceType === "original"
          ? "original"
          : "appended"
      );
      getVersionList(
        selectedOption === "original_csv" ? "original_csv" : "appended_csv"
      );
    }
  }, [selectedVersion]);

  const previewFile = (version: string) => {
    if (fileName !== "") {
      axios
        .get(`${apiUrl + view_csv}/${fileName + ".csv"}`, {
          params: {
            format_name: format_name,
            version:
              selectedVersion || latestVersion
                ? selectedVersion || latestVersion
                : 0,
            type: version,
            orgid: orgID,
            user_id: userID,
          },
        })
        .then((response) => {
          setCSVData(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setError(error?.response?.data?.detail);
          const errorMsg = error?.response?.data?.detail;
          showToast(errorMsg ? errorMsg : "Something went wrong", "error");
          setCSVData("");
        });
    }
  };

  const getVersionList = (optionSelected: string) => {
    const payload = {
      invoice_name: fileName + ".csv",
      format_name: format_name,
      org_id: orgID,
      user_id: userID,
    };
    if (fileName !== "") {
      axios
        .post(`${apiUrl + invoice_version_by_invoice_name}`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let allVersions: string[] = [];
          if (response?.data?.data?.results) {
            // Filter versions based on selected option
            if (
              optionSelected === "original_csv" ||
              invoiceType === "original"
            ) {
              const originalData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "ORIGINAL" ||
                  item.FINVOICE_TYPE === "original"
              );
              allVersions =
                originalData.length > 0
                  ? originalData[0].all_version.split(",")
                  : [];
            } else if (
              optionSelected === "appended_csv" ||
              optionSelected === "" ||
              invoiceType === "appended"
            ) {
              const appendedData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "APPENDED" ||
                  item.FINVOICE_TYPE === "appended"
              );
              allVersions =
                appendedData.length > 0
                  ? appendedData[0].all_version.split(",")
                  : [];
            }
          }
          setVersionList(allVersions);
        })
        .catch((error) => {
          console.error(error);
          showToast(
            "Something went wrong while getting invoice version!",
            "error"
          );
        });
    }
  };

  const handleEdit = () => {
    navigate("/upload_file/editCsv", {
      state: {
        fileName,
        selectedOption,
        format_name,
        orgID,
        userID,
        selectedVersion,
        uploadedFileName,
      },
    });
  };

  // const handleSave = (updatedData: any) => {
  //   setCSVData(updatedData);
  //   setIsEditModalOpen(false);
  //   getVersionList(selectedOption ?? "");
  // };

  const handleDownload = async () => {
    await downloadFile(
      documentName,
      selectedOption,
      selectedVersion,
      latestVersion,
      invoiceType
    )
      .then(() => {
        console.log("csv downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading xml:", error?.response?.statusText);
        const errorMsg = error?.response?.statusText
          ? "File " + error?.response?.statusText
          : "Something went wrong!";
        showToast(errorMsg, "error");
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    getVersionList(value);
    switch (value) {
      case "original_csv":
        previewFile("original");
        break;
      case "appended_csv":
        previewFile("appended");
        break;
      default:
        setSelectedOption("");
        break;
    }
  };

  const handleSelectedVersion = (selected: { value: string }) => {
    // const value = event.target.value;
    const value = selected.value;
    setSelectedVersion(value);
    console.log("selected version", value);
    setError("");
  };

  const handleCloseViewCsv = () => {
    if (back === undefined) {
      navigate("/history");
    } else {
      navigate("/upload_file/File_convertion", {
        state: {
          uploadedFileName,
          isConvertTocsv: true,
          format_name,
        },
      });
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center space-x-4">
        <div className="flex justify-start pl-2">
          <button
            aria-label="Go Back"
            className="flex items-center px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300"
            onClick={handleCloseViewCsv}
          >
            <IconArrowBigLeftFilled
              stroke={1}
              color="#ffffff"
              className="h-6 w-6 mr-2"
            />
            <span className="text-lg">Back</span>
          </button>
        </div>
        <div className="flex justify-end space-x-4 items-center">
          <div className="border border-gray-300 rounded-lg relative">
            <select
              className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              id="dropdown"
              value={selectedOption}
              onChange={handleChange}
            >
              <option value="">View Option</option>
              {selectedOption === "original_csv" ||
              invoiceType === "original" ? (
                <option value="appended_csv">Appended CSV</option>
              ) : (
                <option value="original_csv">Original CSV</option>
              )}
            </select>
            <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2">
              <IconTriangleInvertedFilled className="w-4 h-2 text-gray-400 " />
            </div>
          </div>
          <Dropdown
            options={versionList}
            onChange={handleSelectedVersion}
            value={selectedVersion}
            placeholder="Select Version"
            controlClassName="w-full px-4 py-2 !border-1 !border-blue-700 !rounded-lg !text-blue-600 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            menuClassName="border-2 border-blue-500 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto"
            arrowClassName="mt-1"
          />
          <button
            type="button"
            className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
            onClick={handleEdit}
          >
            Edit
          </button>
          <button
            type="button"
            className="px-4 py-2 w-32  bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
            onClick={handleDownload}
          >
            Download
          </button>
          <CalculatorPage />
        </div>
      </div>
      <h1 className="mb-4 text-blue-700 text-lg font-bold mt-8">
        {selectedOption === "original_csv" || invoiceType === "original"
          ? "Original CSV"
          : "Appended CSV"}
      </h1>
      <div
        className="  items-center justify-between mt-8 overflow-y-scroll border-2 border-gray-300 min-h-96 bg-blue-50 rounded-lg "
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "#3b82f6 #f1f1f1",
        }}
      >
        <div>
          {csvData ? (
            <CsvDataDisplay csvData={csvData} />
          ) : error ? (
            <p className="text-red-500 p-4">{error}</p>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCsv;
