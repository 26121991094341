import { useForm, Controller, SubmitHandler } from "react-hook-form";
import RadioGroupComponent from "../../../components/RadioGroupComponent";
import { useEffect, useState } from "react";
import Select from "react-select";
import { MultiValue } from "react-select";
import axios from "axios";
import {
  EDIT_CONFIG_API,
  GET_FORMAT_NAME,
} from "../../../utils/api-details/ApiList";
import CsvForm from "./CsvForm";
import JsonForm from "./JsonForm";
import XmlForm from "./XmlForm";
import { IconArrowBigLeftFilled } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useToast } from "../../../components/Toast";

interface FormData {
  json_type: string;
  json_url: string;
  json_method?: string;
  json_requestType?: string;
  json_requestBody?: string;
  json_pathParam?: string;
  json_file?: FileList;

  csv_type: string;
  csv_url: string;
  csv_method?: string;
  csv_requestType?: string;
  csv_requestBody?: string;
  csv_pathParam?: string;
  csv_file?: FileList;

  xml_type: string;
  xml_url: string;
  xml_method?: string;
  xml_requestType?: string;
  xml_requestBody?: string;
  xml_pathParam?: string;
  xml_file?: FileList;

  format: string;
  selectedFormats: string[];
}

interface FormatItem {
  INVOICE_FORMAT_ID: number;
  FORMAT_NAME: string;
  status: string;
}

const EditConfigApi = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedLevel, setSelectedLevel] = useState("format_name");
  const [selectedFormat, setSelectedFormat] = useState<string[]>([]);
  const [formatList, setFormatList] = useState<FormatItem[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { editData } = location.state || {};
  const { showToast } = useToast();

  const levels = [
    { Label: "Invoice Format Level", value: "format_name" },
    { Label: "Organization Level", value: "org" },
  ];

  const supportedFormats_options = ["csv", "json", "xml"];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormData>();

  useEffect(() => {
    getFormatDetails();
    if (editData) {
      // If `editData` exists, we map the properties into the form fields
      setValue("json_type", editData?.type === "json" ? editData?.type : "");
      setValue("json_url", editData?.apiURL || "");
      setValue("json_method", editData?.apiMethod || "");
      setValue("json_requestType", editData?.requestType || "");
      setValue("json_requestBody", editData?.requestBody || "");
      setValue("json_file", editData?.attachmentName || "");

      setValue("csv_type", editData?.type === "csv" ? editData?.type : "");
      setValue("csv_url", editData?.apiURL || "");
      setValue("csv_method", editData?.apiMethod || "");
      setValue("csv_requestType", editData?.requestType || "");
      setValue("csv_requestBody", editData?.requestBody || "");
      setValue("csv_file", editData?.attachmentName || "");

      setValue("xml_type", editData?.type === "xml" ? editData?.type : "");
      setValue("xml_url", editData?.apiURL || "");
      setValue("xml_method", editData?.apiMethod || "");
      setValue("xml_requestType", editData?.requestType || "");
      setValue("xml_requestBody", editData?.requestBody || "");
      setValue("xml_file", editData?.attachmentName || "");

      setSelectedFormat([editData?.type].filter((format) => format));
    }
  }, [editData]);

  const getFormatDetails = () => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + GET_FORMAT_NAME}`, payload, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results || [];
        setFormatList(
          results?.map((item: FormatItem) => ({
            INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
            FORMAT_NAME: item.FORMAT_NAME,
            status: item.status,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("Unable to fetch data. Please try again later.", "error");
      });
  };

  const handleLevelChange = (value: string) => setSelectedLevel(value);

  const handleSelectedOptions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSelectedFormat((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );
  };

  const handleBack = () => {
    navigate("/ConfigApi");
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const payload = buildPayload(data);
    axios
      .post(`${apiUrl + EDIT_CONFIG_API}`, payload, {
        headers: { "Content-Type": "application/json" },
        params: { org_id: orgID, user_id: userID },
      })
      .then((response) => {
        console.log("API Configuration Updated Successfully:", response);
        // showToast("API Configuration Updated Successfully","success")
      })
      .catch((error) => {
        console.error("Error updating configuration:", error);
      });
  };

  const buildPayload = (data: FormData) => ({
    supported_format: data.selectedFormats,
    csv: selectedFormat.includes("csv") ? buildFilePayload("csv", data) : null,
    json: selectedFormat.includes("json")
      ? buildFilePayload("json", data)
      : null,
    xml: selectedFormat.includes("xml") ? buildFilePayload("xml", data) : null,
  });

  const buildFilePayload = (type: "csv" | "json" | "xml", data: FormData) => {
    const file = data[`${type}_file` as keyof FormData]?.[0];
    return {
      type: data[`${type}_type` as keyof FormData],
      apiURL: data[`${type}_url` as keyof FormData],
      apiMethod: data[`${type}_method` as keyof FormData],
      requestType: data[`${type}_requestType` as keyof FormData],
      requestBody: data[`${type}_requestBody` as keyof FormData],
      attachmentName: file instanceof File ? file.name : "",
    };
  };

  const BaseForm = () => (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col space-y-4">
        {selectedFormat.includes("csv") && (
          <CsvForm register={register} errors={errors} />
        )}
        {selectedFormat.includes("json") && (
          <JsonForm register={register} errors={errors} />
        )}
        {selectedFormat.includes("xml") && (
          <XmlForm register={register} errors={errors} />
        )}
        <div className="my-4">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );

  return (
    <div className="px-4 pb-10 bg-blue-50">
      <div className="w-full p-8 border-2 border-gray-300 rounded-lg bg-grey-50 flex flex-col pb-10">
        {/* Back Button */}
        <div>
          <IconArrowBigLeftFilled
            stroke={1}
            onClick={handleBack}
            color="#3b82f6"
            className="h-8 w-8"
          />
        </div>

        <h3 className="text-lg font-semibold font-Inter mb-4 mt-4">
          Select the level for setting up Api config:
        </h3>
        <RadioGroupComponent
          selectedValue={selectedLevel}
          handleValueChange={handleLevelChange}
          levels={levels}
        />
        <div>
          <h2 className="text-lg font-medium mb-4 mt-8">Select the formats</h2>
          <div className="mb-4">
            <Controller
              name="selectedFormats"
              control={control}
              // defaultValue={editData?.key || ""}
              render={({ field }) => {
                const sortedOptions = formatList
                  ?.sort((a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID)
                  ?.map((option) => ({
                    label: option.FORMAT_NAME,
                    value: option.FORMAT_NAME,
                    isDisabled: option.status === "IN ACTIVE",
                  }));
                const selectAllOption = {
                  label: "Select All",
                  value: "selectAll",
                };
                const allSelected =
                  field.value?.length === sortedOptions.length;
                const updatedValue = Array.isArray(field.value)
                  ? field.value
                  : allSelected
                  ? ["Selected All"]
                  : [];

                return (
                  <Select
                    {...field}
                    isMulti
                    options={[selectAllOption, ...sortedOptions]}
                    value={updatedValue.map((val: string) => ({
                      label: val === "Selected All" ? "Selected All" : val,
                      value: val,
                    }))}
                    onChange={(
                      selected: MultiValue<{ label: string; value: string }>
                    ) => {
                      const values = selected?.some(
                        (item) => item.value === "selectAll"
                      )
                        ? sortedOptions?.map((option) => option.value)
                        : selected?.map((item) => item.value) || [];
                      field.onChange(values);
                    }}
                    placeholder="Select formats"
                    defaultInputValue={editData?.key || ""}
                  />
                );
              }}
            />
          </div>
        </div>
        <div>
          <h2 className="text-lg font-medium mb-4 mt-6">
            Select the supported output formats
          </h2>
          <div className="flex flex-wrap mb-4">
            {supportedFormats_options?.map((option) => (
              <div key={option} className="text-sm font-medium p-2">
                <label>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedFormat.includes(option)}
                    onChange={handleSelectedOptions}
                    className="mr-2"
                  />
                  {option.toUpperCase()}
                </label>
              </div>
            ))}
          </div>
        </div>
        <BaseForm />
      </div>
    </div>
  );
};

export default EditConfigApi;
