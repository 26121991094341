import React, { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";
import { IconProps } from "@tabler/icons-react";

interface NavLinkItemProps {
  to: string;
  icon: React.ComponentType<IconProps>;
  label?: string;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  isDisabled?: boolean;
}

const NavLinkItem: React.FC<NavLinkItemProps> = ({
  to,
  icon: Icon,
  label,
  onClick,
  isDisabled,
}) => {
  if (isDisabled) {
    return (
      <li className="text-white text-xl opacity-50 cursor-not-allowed m-0 p-0 bg-transparent border-0 font-sans !hover:no-underline">
        <span className="flex items-center">
          <Icon className="mr-2" />
          {label}
        </span>
      </li>
    );
  }
  return (
    <li className="m-0 p-0 bg-transparent border-0 font-sans !hover:no-underline">
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive
            ? "!text-yellow-300 !text-xl"
            : "!text-white text-xl !hover:text-yellow-200"
        }
        onClick={onClick}
        aria-disabled={isDisabled}
      >
        <Icon className="inline-block mr-2" stroke={2} />
        {label}
      </NavLink>
    </li>
  );
};

export default NavLinkItem;
