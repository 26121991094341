import { IconMenu2 } from "@tabler/icons-react";
import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import productIcon from "./../../assets/productIcon.svg";
import ProfileDropdown from "./ProfileDropdown";
import Sidebar from "./Sidebar";

const Navbar = ({
  isSidebarOpen,
  setSidebarOpen,
}: {
  isSidebarOpen?: boolean;
  setSidebarOpen?: (val: boolean) => void;
}) => {
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFormatOpen, setIsFormatOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleNavbar = useCallback(() => {
    if (setSidebarOpen) {
      setSidebarOpen(!isSidebarOpen);
    }
  }, [isSidebarOpen, setSidebarOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsFormatOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    const retrievedDataString = sessionStorage.getItem("user_type");
    if (retrievedDataString === "A") {
      console.log("retrievedDataString", retrievedDataString);
      setIsAdmin(true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    navigate("/");
    if (setSidebarOpen) {
    setSidebarOpen(false)
  }
  };

  const toggleFormatDropdown = () => {
    setIsFormatOpen((prevState) => !prevState);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (setSidebarOpen) {
      setSidebarOpen(!isSidebarOpen);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (setSidebarOpen) {
      setSidebarOpen(!isSidebarOpen);
    }
  };
  return (
    <div>
      <div className="flex flex-wrap w-full">
        {/* Top Row */}
        <div className="w-full bg-white shadow-lg rounded-lg p-2 mb-4 card border-2">
          <div className="flex items-center justify-between">
            <div className="flex items-start space-x-12">
              <button
                onClick={toggleNavbar}
                className="absolute top-4 left-4 text-white text-2xl z-50"
                aria-label="Toggle Menu"
              >
                <IconMenu2
                  stroke={2}
                  className="w-7 h-7 m-1 "
                  color={"#2b6cb0"}
                  // color={isSidebarOpen ? "white" : "#2b6cb0"}
                />
              </button>
              <img
                src={productIcon}
                alt="Invoicify"
                className="w-36 max-w-full"
              />
            </div>
            <div className="flex-shrink-0 mb-2 w-5/12 h-full">
              <ProfileDropdown handleLogout={handleLogout} />
            </div>
          </div>
        </div>

        <Sidebar
          isSidebarOpen={isSidebarOpen}
          isHovered={isHovered}
          isAdmin={isAdmin}
          isFormatOpen={isFormatOpen}
          toggleFormatDropdown={toggleFormatDropdown}
          handleMouseLeave={handleMouseLeave}
          handleMouseEnter={handleMouseEnter}
          handleLogout={handleLogout}
        />
      </div>
    </div>
  );
};

export default Navbar;
