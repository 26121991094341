import { IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import FieldSection from "./FieldSection";
import { useToast } from "../../../../components/Toast";

interface ManuallyAddFieldsProps {
  handleAddField: (format: string) => void;
  handleRemoveField: (index: number, format: string) => void;
  isToggledForHeader: boolean;
  fieldValue: string;
  setFieldCsvValue: React.Dispatch<React.SetStateAction<string>>;
  setFieldJsonValue: React.Dispatch<React.SetStateAction<string>>;
  setFieldXmlValue: React.Dispatch<React.SetStateAction<string>>;
  setFieldValue: React.Dispatch<React.SetStateAction<string>>;
  fieldsList: string[];
  xmlFields: string[];
  jsonFields: string[];
  csvFields: string[];
  supportedOutput: string[];
  fieldCsvValue: string;
  fieldJsonValue: string;
  fieldXmlValue: string;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setCsvFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setJsonFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setXmlFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setIsErrorInStep: (value: boolean) => void;
}

const ManuallyAddFields: React.FC<ManuallyAddFieldsProps> = ({
  handleAddField,
  handleRemoveField,
  isToggledForHeader,
  fieldValue,
  setFieldCsvValue,
  setFieldJsonValue,
  setFieldXmlValue,
  setFieldValue,
  fieldsList,
  xmlFields,
  jsonFields,
  csvFields,
  supportedOutput,
  fieldCsvValue,
  fieldJsonValue,
  fieldXmlValue,
  setFiles,
  setCsvFiles,
  setJsonFiles,
  setXmlFiles,
  setIsErrorInStep,
}) => {
  // Mandatory fields that are required for all formats
  const mandatoryFields = [
    "invoice_no",
    "invoice_date",
    "duedate",
    "total_amount",
    "total_gst",
    "payable_to",
    "invoice_currency",
    "buyer_name",
    "commodity_data",
    "taxes_table",
    "charges_table",
  ];
  const { showToast } = useToast();

  useEffect(() => {
    setFiles([]);
    setCsvFiles([]);
    setJsonFiles([]);
    setXmlFiles([]);
  }, [setCsvFiles, setFiles, setJsonFiles, setXmlFiles]);

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    if (newValue.includes(" ")) {
      showToast("Spaces are replaced by underscores", "success");
    }
    const updatedValue = newValue.replace(/\s+/g, "_"); // Replace spaces with underscores
    setFieldValue(updatedValue);
  };

  return (
    <div>
      {isToggledForHeader ? (
        <>
          <div className="flex items-center justify-center mb-4 mt-2">
            <input
              type="text"
              value={fieldValue}
              onChange={handleChange}
              placeholder="Enter field name"
              className="border border-gray-300 rounded-lg p-2 mr-2 w-72 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              onKeyDown={(e) => {
                if (e.key === "Enter") handleAddField("");
              }}
            />
            <button
              onClick={() => handleAddField("")}
              className="bg-blue-600 text-white rounded-lg py-2 px-4 transition-colors hover:bg-blue-700"
            >
              Add Field
            </button>
            {/* {error && <p className="mt-1 text-red-500">{error}</p>} */}
          </div>
          {/* All supported format Section */}
          <div className="flex flex-wrap items-center justify-center gap-2">
            {fieldsList.map((field, index) => (
              <div
                key={index}
                className="relative flex items-center justify-between p-2 w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3 border-2 border-gray-200 rounded-lg bg-gray-200 mb-2"
              >
                <div className="flex items-center">
                  <div className="ml-4 flex flex-col">
                    <p className="text-gray-700">{field}</p>
                  </div>
                </div>
                <IconX
                  onClick={() => handleRemoveField(index, "")}
                  stroke={1}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <div>
          <div className="flex space-x-12 ml-1">
            {/* CSV Section */}
            {supportedOutput.includes("csv") && (
              <FieldSection
                format="csv"
                fieldValue={fieldCsvValue}
                setFieldValue={setFieldCsvValue}
                handleAddField={handleAddField}
                fields={csvFields}
                setFieldValueFunction={setFieldCsvValue}
                handleRemoveField={handleRemoveField}
              />
            )}
            {/* JSON Section */}
            {supportedOutput.includes("json") && (
              <FieldSection
                format="json"
                fieldValue={fieldJsonValue}
                setFieldValue={setFieldJsonValue}
                handleAddField={handleAddField}
                fields={jsonFields}
                setFieldValueFunction={setFieldJsonValue}
                handleRemoveField={handleRemoveField}
              />
            )}
            {/* XML Section */}
            {supportedOutput.includes("xml") && (
              <FieldSection
                format="xml"
                fieldValue={fieldXmlValue}
                setFieldValue={setFieldXmlValue}
                handleAddField={handleAddField}
                fields={xmlFields}
                setFieldValueFunction={setFieldXmlValue}
                handleRemoveField={handleRemoveField}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManuallyAddFields;
