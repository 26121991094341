import { useState } from "react";
import Modal from "../../components/Modal";

const TakeDemo = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (onClose) onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title=" " size="w-4/12">
      <form className="p-6" onSubmit={handleSubmit}>
        <div className="mb-6 text-center">
          <h1 className="text-2xl font-semibold text-gray-800 mb-4">
            Please provide your email, and we’ll connect with you soon!
          </h1>
          <p className="text-sm text-gray-500">
            We’ll send a demo invite to your email.
          </p>
        </div>

        <div className="mb-6">
          <input
            className="block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition duration-200 ease-in-out focus:ring-4 focus:ring-indigo-200"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default TakeDemo;
