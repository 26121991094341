import axios from "axios";
import { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { useToast } from "../../../components/Toast";
import { GET_API_CONFIG_LIST } from "../../../utils/api-details/ApiList";
import { useNavigate } from "react-router-dom";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";

interface FileTypeDetails {
  apiMethod: string;
  apiURL: string;
  attachmentName: string;
  requestBody: string;
  requestType: string;
  type: string;
}

interface ApiConfigData {
  [key: string]: {
    [fileType: string]: FileTypeDetails;
  };
}

interface TableData {
  key: string;
  apiMethod: string;
  apiURL: string;
  attachmentName: string;
  requestBody: string;
  requestType: string;
  type: string;
}

const ConfigApiList = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const { showToast } = useToast();
  const get_config_api_list = GET_API_CONFIG_LIST;
  const [List, setList] = useState<TableData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getConfigApiDetails();
  }, []);

  const getConfigApiDetails = () => {
    axios
      .get(`${apiUrl + get_config_api_list}/${orgID}`)
      .then((response) => {
        const data = response?.data?.files["api_config.json"] as ApiConfigData;
        if (data) {
          const apiConfigArray: TableData[] = [];
          Object.entries(data).forEach(([key, fileTypes]) => {
            // For each file type (csv, json, xml)
            Object.entries(fileTypes).forEach(([type, details]) => {
              apiConfigArray.push({
                key,
                apiMethod: details.apiMethod,
                apiURL: details.apiURL,
                attachmentName: details.attachmentName,
                requestBody: details.requestBody || "",
                requestType: details.requestType,
                type: type,
              });
            });
          });
          setList(apiConfigArray);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast("We encountered an issue while retrieving the data. Please try again later.", "error");
      });
  };

  // Handle the Edit action
  const handleEdit = (row: TableData) => {
    setIsEdit(true);
    const editData = row;
      navigate('/editConfigApi', { state: { editData } });
  };

  // Handle the Delete action
  const handleDelete = (row: TableData) => {
    setList(
      List.filter((item) => item.key !== row.key || item.apiURL !== row.apiURL)
    );
    showToast("Row deleted successfully", "success");
  };

  // Filter the list based on search term
  const filteredList = List.filter(
    (row) =>
      row.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.apiURL.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.apiMethod.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      name: "Key",
      selector: (row: TableData) => row.key,
      sortable: true,
      filterable: true,
    },
    {
      name: "API Method",
      selector: (row: TableData) => row.apiMethod,
      sortable: true,
      filterable: true,
    },
    {
      name: "API URL",
      selector: (row: TableData) => row.apiURL,
      sortable: true,
    },
    {
      name: "Attachment Name",
      selector: (row: TableData) => row.attachmentName,
      sortable: true,
    },
    // {
    //   name: "Request Body",
    //   selector: (row: TableData) => row.requestBody,
    //   sortable: true,
    // },
    {
      name: "Request Type",
      selector: (row: TableData) => row.requestType,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row: TableData) => row.type,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row: TableData) => (
        <div className="flex space-x-2">
          <IconEdit stroke={1} onClick={() => handleEdit(row)} />
          <IconTrash stroke={1} onClick={() => handleDelete(row)} />
        </div>
      ),
    },
  ];

  const handleAddConfig = () => {
    navigate("/addConfigApi");
  };

  return (
  <div className="bg-blue-50">
      {!isEdit && (
        <div className="px-4 pb-8">
          <div className="flex flex-col items-end relative pr-6 pt-4">
            <button
              type="button"
              onClick={handleAddConfig}
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 inline-end"
            >
              <IconPlus stroke={2} className="mr-2" />
              ADD
            </button>
          </div>
          <div className="mt-8">
            <TableComponent
              title="API Configuration Details"
              columns={columns}
              rows={filteredList}
              selectableRows
              pagination
              highlightOnHover
              striped
              persistTableHead
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigApiList;
