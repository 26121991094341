import { IconCloudUpload, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  BATCH_PROCESS,
  UPLOAD_INVOICE,
} from "../../../../utils/api-details/ApiList";
import { useToast } from "../../../../components/Toast";

interface FileExtractedData {
  [key: string]: any;
}

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}

const UploadInvoice = ({
  setHasChanges,
  uploadInvoicefiles,
  setUploadInvoiceFiles,
  isError,
  fileExtractedData,
  setFileExtractedData,
  setDictionaryMappingRequired,
  setBuyerSupplier,
  fileData,
  setFileData,
}: {
  setHasChanges: (value: boolean) => void;
  uploadInvoicefiles: File[];
  setUploadInvoiceFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isError: boolean;
  fileExtractedData: FileExtractedData;
  setFileExtractedData: React.Dispatch<React.SetStateAction<FileExtractedData>>;
  setDictionaryMappingRequired: (value: string) => void;
  setBuyerSupplier: React.Dispatch<React.SetStateAction<BuyerSupplierData[]>>;
  fileData: Record<string, any>;
  setFileData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [extractedData, setExtractedData] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlBulkUpload = process.env.REACT_APP_API_URL_Bulk;
  const userType = sessionStorage.getItem("user_type");
  const upload_invoice = UPLOAD_INVOICE;
  const batch_process = BATCH_PROCESS;
  const orgID = sessionStorage.getItem("orgId");
  const { showToast } = useToast();

  useEffect(() => {
    setHasChanges(uploadInvoicefiles.length > 0);
  }, [setHasChanges, uploadInvoicefiles]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const acceptedFiles = Array.from(event.dataTransfer.files).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    // Check if adding these files will exceed the limit of 10
    const newFiles = [...uploadInvoicefiles, ...acceptedFiles];
    if (newFiles.length > 10) {
      showToast("You can only upload up to 10 files.", "error");
      return;
    }
    // Update the state if the number of files is within the limit
    setUploadInvoiceFiles(newFiles);
    setExtractedData(extractDataFromFiles(acceptedFiles));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles = Array.from(event.target.files || []).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    // Check if adding these files will exceed the limit of 10
    const newFiles = [...uploadInvoicefiles, ...acceptedFiles];
    if (newFiles.length > 10) {
      showToast("You can only upload up to 10 files.", "error");
      return;
    }
    // Update the state if the number of files is within the limit
    setUploadInvoiceFiles(newFiles);
    setExtractedData(extractDataFromFiles(acceptedFiles));
    setHasChanges(true);
  };

  const handleButtonClick = () => {
    const fileInput = document.querySelector(
      "input[type='file']"
    ) as HTMLInputElement;
    fileInput.click();
  };

  const handleCancel = (fileToRemove: File) => {
    setUploadInvoiceFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
    setExtractedData((prevData) =>
      prevData.filter((data) => !data.includes(fileToRemove.name))
    );
  };

  const extractDataFromFiles = (files: File[]) => {
    return files.map((file) => `Data from ${file.name}`);
  };

  const extractKeys = (obj: Record<string, any>, parentKey = "") => {
    let keys: string[] = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (Array.isArray(obj[key])) {
          keys.push(`${newKey}`);
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          keys = keys.concat(extractKeys(obj[key], newKey));
        } else {
          keys.push(newKey);
        }
      }
    }
    return keys;
  };

  const uploadFilesToAPI = (files: File[]) => {
    const api = files?.length === 1 ? upload_invoice : batch_process;
    const url = files.length === 1 ? apiUrl : apiUrlBulkUpload;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(files?.length === 1 ? "file" : "files", file);
    });
    axios
      .post(`${url + api}`, formData, {
        params: {
          user_type: userType === "A" ? "admin" : "user",
          org_id: orgID,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("response", response);
        setIsLoading(false);
        const result = response?.data?.message;
        setFileData(result);
        if (files?.length === 1) {
          setBuyerSupplier([
            { supplierName: result?.payable_to, buyerName: result?.buyer_name },
          ]);
        } else {
          const buyerSupplierData: {
            buyerName: string;
            supplierName: string;
          }[] = [];
          result?.forEach((result: any) => {
            const { buyer_name, payable_to } = result.file_data;
            buyerSupplierData.push({
              buyerName: buyer_name,
              supplierName: payable_to,
            });
          });
          // Remove duplicates based on buyerName and supplierName
          const uniqueBuyerSupplierData = buyerSupplierData.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.buyerName === value.buyerName &&
                  t.supplierName === value.supplierName
              )
          );
          // Now update the state with the unique buyer and supplier data
          setBuyerSupplier(uniqueBuyerSupplierData);
        }
        // Set dictionary mapping and file data extraction
        const dictionaryMapping = response?.data?.dictionary_mapping_required;
        const dictionaryMappingForBulk =
          response?.data?.message[0]?.dictionary_mapping_required;
        const dictionary =
          files?.length === 1 ? dictionaryMapping : dictionaryMappingForBulk;
        setDictionaryMappingRequired(dictionary);
        const responseData =
          files?.length === 1 ? result : result[0]?.file_data;
        const data = extractKeys(responseData);
        setFileExtractedData(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error uploading files:", error);
        const errorMsg =
          error?.response?.data?.detail ||
          "An error occurred during the file upload";
        console.error("Error uploading files:", error);
        showToast(errorMsg, "error");
      });
  };

  const handleExtractClick = () => {
    setIsLoading(true);
    uploadFilesToAPI(uploadInvoicefiles);
  };

  const handleClearAll = () => {
    setUploadInvoiceFiles([]);
  };

  return (
    <div className="flex flex-wrap justify-between gap-6">
      <div className="w-full h-auto p-4 bg-white border-2 shadow-md rounded-lg">
        <h2 className="font-semibold text-xl text-blue-700">UPLOAD FILE</h2>
        <hr className="my-2" />
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`border-2 p-4 h-72 rounded-lg text-center ${
            isDragOver
              ? "bg-blue-50 border-blue-500"
              : "bg-gray-100 border-gray-300"
          }`}
        >
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="hidden"
            multiple
            max={10}
          />
          <div className="flex flex-col justify-center items-center">
            <IconCloudUpload stroke={2} color="#3b82f6" className="h-14 w-14" />
            <p className="mt-2 text-gray-700">
              Choose a file or Drag & drop it here
            </p>
            <button
              onClick={handleButtonClick}
              className="mt-4 w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Browse Files
            </button>
          </div>
        </div>
        {/* File List */}
        <div
          className="mt-6 max-h-36"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
            overflowX: "auto",
            WebkitOverflowScrolling: "touch",
            whiteSpace: "nowrap",
          }}
        >
          <div
            className={`p-2 grid gap-2 ${
              uploadInvoicefiles.length > 4 ? "grid-cols-2" : "grid-cols-1"
            }`}
          >
            {uploadInvoicefiles.map((file, index) => {
              const truncatedName =
                file.name.length > 30
                  ? `${file.name.slice(0, 30)}...`
                  : file.name;
              return (
                <div
                  key={index}
                  className="relative flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-gray-100 group"
                >
                  <div className="flex items-center">
                    <p className="text-gray-700 truncate">{truncatedName}</p>
                  </div>

                  {file?.name?.length > 30 && (
                    <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded-md shadow-md">
                      {file.name}
                    </div>
                  )}
                  <IconX
                    onClick={() => handleCancel(file)}
                    stroke={1}
                    className="cursor-pointer text-gray-700 hover:text-red-500 transition-colors"
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* Extract Button */}
        {uploadInvoicefiles.length >= 1 && (
          <div className="mt-4 flex justify-center space-x-4">
            <button
              onClick={handleExtractClick}
              className="w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              disabled={isLoading || uploadInvoicefiles.length === 0}
            >
              {isLoading ? "Extracting..." : "Extract"}
            </button>

            <button
              onClick={handleClearAll}
              className="w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              disabled={isLoading || uploadInvoicefiles.length === 0}
            >
              Clear
            </button>
          </div>
        )}
      </div>

      {/* Extracted Fields Section */}
      <div className="w-full  p-6 bg-white border-2 shadow-md rounded-lg overflow-auto">
        <h2 className="font-semibold text-xl">Extracted Fields</h2>
        <hr className="my-2" />
        <div>
          {uploadInvoicefiles.length > 0 ? (
            <pre className="p-4 bg-gray-50 rounded-lg whitespace-pre-wrap overflow-x-auto text-sm">
              {!fileData ||
              (Object.keys(fileData).length === 0 &&
                fileData.constructor === Object) ? (
                <p className="text-center text-gray-500">No data</p>
              ) : (
                JSON.stringify(fileData, null, 2)
              )}
            </pre>
          ) : (
            <p className="text-center text-gray-500">No data</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadInvoice;
