import { useState, useEffect } from "react";
import { Download } from "../../components/Download";
import axios from "axios";
import {
  DOWNLOAD_CSV,
  DOWNLOAD_JSON,
  DOWNLOAD_XML,
} from "../../utils/api-details/ApiList";
import pdf from "./../../assets/pdf-icon.png";
import { IconArrowBigLeftFilled, IconDownload } from "@tabler/icons-react";
import { useToast } from "../../components/Toast";
import { useLocation, useNavigate } from "react-router-dom";

export const ConfirmationPopup = () => {
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState("");
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_API_URL;
  const download_json = DOWNLOAD_JSON;
  const download_csv = DOWNLOAD_CSV;
  const download_xml = DOWNLOAD_XML;
  const { showToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isConvertToJson,
    isConvertTocsv,
    isConvertToXml,
    uploadedFileName,
    format_name,
    // setFileName,
    // setUploadInvoiceFiles,
    // setSelectedOption,
  } = location.state || {};

  const [isConvertJson, setIsConvertJson] = useState(isConvertToJson);
  const [isConvertCsv, setIsConvertCsv] = useState(isConvertTocsv);
  const [isConvertXml, setIsConvertXml] = useState(isConvertToXml);

  useEffect(() => {
    console.log("uploadedFileName",uploadedFileName)
    setIsConvertJson(isConvertToJson);
    setIsConvertCsv(isConvertTocsv);
    setIsConvertXml(isConvertToXml);
  }, [isConvertToJson, isConvertTocsv, isConvertToXml, isConvertJson]);

  const handleView = (name: string) => {
    setSelectedFile("");
    const documentName = name.replace("_mapped", "");
    setSelectedFile(documentName);
    if (isConvertJson) {
      const back = "true";
      setIsViewModalOpen(true);
      navigate("/upload_file/viewJson", {
        state: {
          isViewModalOpen,
          documentName,
          format_name,
          back,
          uploadedFileName,
        },
      });
    } else if (isConvertTocsv) {
      const back = "true";
      navigate("/upload_file/viewCsv", {
        state: {
          isViewModalOpen,
          documentName,
          format_name,
          back,
          uploadedFileName,
        },
      });
      setIsViewModalOpen(false);
    } else if (isConvertToXml) {
      const back = "true";
      navigate("/upload_file/viewXml", {
        state: {
          isViewModalOpen,
          documentName,
          format_name,
          back,
          uploadedFileName,
        },
      });
      setIsViewModalOpen(false);
    }
  };

  const handleDownloadFile = (filename: string) => {
    const name = filename.replace("_mapped", "");
    let fileName = name?.split(".")[0];
    // Handle JSON download
    if (isConvertToJson) {
      fileName += ".json";
      axios
        .get(`${apiUrl + download_json}`, {
          params: {
            filename: fileName,
            format_name: format_name,
            version: 0,
            type: "appended",
            orgid: orgID,
            user_id: userID,
          },
        })
        .then((response) => {
          Download(response.data, fileName);
        })
        .catch((error) => {
          console.error("Error downloading JSON file:", error);
          const errorMsg =
            error?.response?.data?.detail ||
            "We are currently unable to download the requested JSON file. Please try again later";
          showToast(errorMsg, "error");
        });
    }

    // Handle CSV download
    else if (isConvertTocsv) {
      fileName += ".csv";
      axios
        .get(`${apiUrl + download_csv}`, {
          params: {
            filename: fileName,
            format_name: format_name,
            version: 0,
            type: "appended",
            orgid: orgID,
            user_id: userID,
          },
          responseType: "blob",
        })
        .then((response) => {
          const contentDisposition = response.headers["content-disposition"];
          const filenameToDownload = contentDisposition
            ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
            : fileName;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = filenameToDownload;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading CSV file:", error);
          const errorMsg =
            error?.response?.data?.detail ||
            "We are currently unable to download the requested CSV file. Please try again later";
          showToast(errorMsg, "error");
        });
    }

    // Handle XML download
    else if (isConvertToXml) {
      fileName += ".xml";
      axios
        .get(`${apiUrl + download_xml}`, {
          params: {
            filename: fileName,
            format_name: format_name,
            version: 0,
            type: "appended",
            orgid: orgID,
            user_id: userID,
          },
          responseType: "blob",
        })
        .then((response) => {
          if (response.headers["content-type"] === "application/xml") {
            const file = new Blob([response.data], { type: "application/xml" });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = response.headers["content-disposition"]
              ? response.headers["content-disposition"]
                  .split("filename=")[1]
                  .replace(/['"]/g, "")
              : fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
          } else {
            throw new Error(
              "Unexpected content type: " + response.headers["content-type"]
            );
          }
        })
        .catch((error) => {
          console.error("Error downloading XML file:", error);
          const errorMsg =
            error?.response?.data?.detail ||
            "We are currently unable to download the requested XML file. Please try again later";
          showToast(errorMsg, "error");
        });
    }
  };

  const handleBack = () => {
    navigate("/upload_file");
  };
  return (
    <div>
      {!isViewModalOpen && (
        <div className="p-6">
          <div className="items-center justify-between my-4">
            {isConvertJson && (
              <>
                <div className="flex justify-start">
                  <button
                    aria-label="Go Back"
                    className="flex items-center px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300"
                    onClick={handleBack}
                  >
                    <IconArrowBigLeftFilled
                      stroke={1}
                      color="#ffffff"
                      className="h-6 w-6 mr-2"
                    />
                    <span className="text-lg">Back</span>
                  </button>
                </div>
                <h2 className="font-semibold text-2xl text-blue-700 mt-6 mb-6">
                  File is successfully converted into JSON
                </h2>
              </>
            )}
            {isConvertCsv && (
              <>
                <div>
                  <IconArrowBigLeftFilled
                    stroke={1}
                    onClick={handleBack}
                    color="#3b82f6"
                    className="h-8 w-8"
                  />
                </div>
                <h2 className="font-semibold text-2xl text-blue-700 mt-6 mb-6">
                  File is successfully converted into CSV
                </h2>
              </>
            )}
            {isConvertXml && (
              <>
                <div>
                  <IconArrowBigLeftFilled
                    stroke={1}
                    onClick={handleBack}
                    color="#3b82f6"
                    className="h-8 w-8"
                  />
                </div>
                <h2 className="font-semibold text-2xl text-blue-700 mt-6 mb-6">
                  File is successfully converted into XML
                </h2>
              </>
            )}

            {uploadedFileName?.length === 1 ? (
              <div className="flex flex-col items-center mt-8">
                 <div className="flex items-center space-x-4 flex-grow p-4 border-2 border-gray-300 rounded-lg">
                        <img src={pdf} alt="pdf-icon" className="h-10 w-10" />
                        <div className="ml-4 flex flex-col">
                          <p className="text-gray-700">{uploadedFileName}</p>
                        </div>
                      </div>
                <div className="flex space-x-4 mt-6">
                  <button
                    type="button"
                    className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                    onClick={() => handleDownloadFile(uploadedFileName[0])}
                  >
                    Download
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                    onClick={() => handleView(uploadedFileName[0])}
                  >
                    View
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="mt-6"
                style={{
                  scrollbarWidth: "thin",
                  scrollbarColor: "#3b82f6 #f1f1f1",
                  overflowX: "auto",
                  WebkitOverflowScrolling: "touch",
                  whiteSpace: "nowrap",
                }}
              >
                <div>
                  {uploadedFileName?.map((file: string, index: number) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group"
                    >
                      <div className="flex items-center space-x-4 flex-grow">
                        <img src={pdf} alt="pdf-icon" className="h-10 w-10" />
                        <div className="ml-4 flex flex-col">
                          <p className="text-gray-700">{file}</p>
                        </div>
                      </div>
                      <div className="ml-auto flex space-x-4 items-center">
                        <button
                          type="button"
                          className="px-4 py-2 w-20 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                          onClick={() => handleView(file)}
                          aria-label={`View ${file}`}
                        >
                          View
                        </button>
                        <IconDownload
                          stroke={2}
                          color="grey"
                          onClick={() => handleDownloadFile(file)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmationPopup;
