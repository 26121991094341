import { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import pdf from "./../../assets/pdf-icon.png";

interface FailedFile {
  error: string;
  filename: string;
}

export const FailedFilesWarning = ({
  failedFile,
  msgFor,
  setFailedFile,
  setHasError,
}: {
  failedFile?: { error: string; filename: string }[];
  msgFor?: string;
  setFailedFile?: (files: FailedFile[]) => void;
  setHasError?: (value: boolean) => void;
}) => {
  const [isFailedFilesOpen, setIsFailedFilesOpen] = useState<boolean>(true);

  useEffect(() => {
    setIsFailedFilesOpen(true);
  }, [failedFile]);

  const handleClose = () => {
    setIsFailedFilesOpen(false);
    if (setFailedFile) {
      setFailedFile([]);
    }
    if (setHasError) {
      setHasError(false);
    }
  };
  return (
    <div>
      <div>
      {failedFile?.length !== 0 &&
        <Modal
          isOpen={isFailedFilesOpen}
          onClose={handleClose}
          title="Failed Files"
          showCancelButton={false}
          size="w-5/12"
        >
          <div
            className="p-4 items-center justify-between max-h-96 overflow-auto"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          >
            <p className="text-lg">
              The following {failedFile?.length === 1 ? "file" : "files"} have
              failed the {msgFor}:
            </p>
            {/* Display the failed files list */}
            <div className="mt-4">
              {failedFile?.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group min-w-96 overflow-auto"
                  style={{
                    scrollbarWidth: "thin",
                    scrollbarColor: "#3b82f6 #f1f1f1",
                  }}
                >
                  <div className="flex items-center space-x-4 flex-grow">
                    <img src={pdf} alt="pdf-icon" className="h-10 w-10" />
                    <div className="ml-4 flex flex-col">
                      <p className="text-gray-700">{file.filename}</p>
                      {msgFor === "upload" ? (
                        <p className="text-red-500 text-sm">
                          Excluding from the upload list because of data or
                          format differences
                        </p>
                      ) : (
                        <p className="text-red-500 text-sm">{file.error}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
}
      </div>
    </div>
  );
};

export default FailedFilesWarning;
