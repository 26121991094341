import { SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { EDIT_JSON, VIEW_JSON } from "../../../utils/api-details/ApiList";
import { useToast } from "../../../components/Toast";
import { useLocation, useNavigate } from "react-router-dom";

interface DataType {
  [key: string]: any;
}

const EditJson = () => {
  const location = useLocation();
  const {
    fileName,
    selectedOption,
    format_name,
    orgID,
    userID,
    selectedVersion,
    uploadedFileName,
  } = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL;
  const [jsonData, setJsonData] = useState("");
  const [editableData, setEditableData] = useState("");
  const [type, setType] = useState("");
  const view_json = VIEW_JSON;
  const edit_json = EDIT_JSON;
  const { showToast } = useToast();
  const navigate = useNavigate();

  const updatedFile = () => {
    const name = fileName + ".json";
    axios
      .get(`${apiUrl + view_json}/${name}`, {
        params: {
          format_name: format_name,
          version: selectedVersion === "" ? 0 : selectedVersion,
          type: type === "" ? "appended" : type,
          orgid: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        setJsonData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        showToast("Something went wrong", "error");
      });
  };

  useEffect(() => {
    if (selectedOption === "original_json") {
      setType("original");
    } else {
      setType("appended");
    }
    updatedFile();
  }, [selectedOption]);

  useEffect(() => {
    setEditableData(JSON.stringify(jsonData, null, 2));
  }, [jsonData]);

  // Function to handle changes in the text area
  const handleTextChange = (event: { target: { value: SetStateAction<string> } }) => {
    setEditableData(event.target.value);
  };

  const handleSaveClick = () => {
    axios
      .patch(
        `${apiUrl + edit_json}/${fileName + ".json"}`,
        JSON.parse(editableData),
        {
          params: {
            format_name: format_name,
            selected_version: selectedVersion === "" ? 0 : selectedVersion,
            type: type,
            orgid: orgID,
            user_id: userID,
          },
        }
      )
      .then((response) => {
        updatedFile();
      })
      .catch(function (error) {
        console.log(error);
        showToast("Something went wrong", "error");
      });

    const parsedData = JSON.parse(editableData);
    navigate("/upload_file/viewJson", {
      state: {
        isViewModalOpen: true,
        documentName: fileName,
        format_name,
        back: true,
        uploadedFileName,
        editedData: parsedData,
      },
    });
  };

  const handleCancelClick = () => {
    navigate("/upload_file/viewJson", {
      state: {
        isViewModalOpen: true,
        documentName: fileName,
        format_name,
        back: true,
        uploadedFileName
      },
    });
  };

  return (
    <div className="flex flex-col h-screen p-4">
        <div className="mt-auto flex justify-end space-x-4">
        <button
          type="button"
          className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
          onClick={handleSaveClick}
          aria-label="Save changes"
        >
          Save
        </button>
        <button
          type="button"
          className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
          onClick={handleCancelClick}
          aria-label="Cancel changes"
        >
          Cancel
        </button>
      </div>
      <div className="flex-grow mt-8 p-2">
        {/* Textarea container */}
        <textarea
          className="p-2 rounded-lg h-full w-full border-2 border-gray-300 bg-blue-50"
          value={editableData}
          onChange={handleTextChange}
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
            overflowY: "auto", 
            WebkitOverflowScrolling: "touch", 
            whiteSpace: "nowrap", 
          }}
        />
      </div>
    
    </div>
  );
};

export default EditJson;