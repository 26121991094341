import StepperComponent from "../Stepper";

const CreateFormat = () => {
  return (
    <div>
      <div className="px-4 pb-8">
        <StepperComponent />
      </div>
    </div>
  );
};

export default CreateFormat;
